import React, { Component, useState } from "react";
import { getTheme, setTheme, changeTheme, getDefaultValue } from "../../utils";
import validator from "validator";
import { loadSettings, saveNewsletter } from "../../utils/Network";
import { useEffect } from "react";
import { useStateValue } from "../../StateProvider";

export default function Footer({  }) {
  const [selected_theme, setSelectedTheme] = useState(getTheme());
  const [newsletter_email, setNewsLetterEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [settings, setSettings] = useState(false);

  const [{ translations }] = useStateValue();

  useEffect(() => {
    setTheme();
    loadSettings().then((settings) => {
      setSettings(settings);
    });
  }, []);

  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x=>x.key == str );

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  const setCurrentTheme = (e) => {
    changeTheme(e);

    setSelectedTheme(getTheme);
  };

  const saveChanges = (e) => {
    e.preventDefault();

    setLoading(true);
    setDone(false);
    setError(false);
    if (!newsletter_email || !validator.isEmail(newsletter_email)) {
      setError("Email address is not valid");
      setLoading(false);
      return;
    }

    saveNewsletter(newsletter_email).then((data) => {
      setLoading(false);

      if (data?.id) {
        setDone(translate("Your email address added to our newsletter list"));
        setNewsLetterEmail("");
      } else
        setError(
          translate("This email address already exists in newsletter list")
        );
    });
  };

  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-ribbon">
          <span className="kfont">{translate("Follow Us")}</span>
        </div>
        <div className="row py-5 my-4">
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
            <h5 className="text-3 mb-3 kfont lan-text-right">
              {translate("NEWSLETTER")}
            </h5>
            <p className="pr-1 lan-text-right">
              {translate(
                "Keep up on our always evolving product features and technology. Enter your e-mail address and subscribe to our newsletter."
              )}
            </p>

            <form
              id="newsletterForm"
              action=""
              method="POST"
              className="mb-3 mb-md-0"
            >
              <div className="input-group input-group-rounded">
                <input
                  className="form-control border-primary form-control-sm border-r-radius-0"
                  placeholder={translate("Email")}
                  value={newsletter_email}
                  onChange={(e) => {
                    setNewsLetterEmail(e.target.value);
                  }}
                  name="newsletterEmail"
                  id="newsletterEmail"
                  type="text"
                />
                <span className="input-group-append">
                  <button
                    className="btn btn-primary border-primary"
                    onClick={saveChanges}
                    type="submit"
                  >
                    <strong>{translate("GO!")}</strong>
                  </button>
                </span>
              </div>
            </form>
            {loading && (
              <div className="alert alert-warning mt-2 ">
                {translate("Please wait...")}
              </div>
            )}
            {done && (
              <div className="alert alert-success mt-2 " id="newsletterSuccess">
                {translate("Success! You've been added to our email list.")}
              </div>
            )}
            {error && (
              <div className="alert alert-danger " id="newsletterError">
                {error}
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-6 mb-4 mb-lg-0"></div>

          <div className="col-md-6 col-lg-2">
            <h5 className="text-3 mb-1 lan-text-right kfont">
              {translate("Theme")}
            </h5>
            <div className="mb-40 lan-text-right kfont">
              <a href="#" onClick={setCurrentTheme}>
                {translate(selected_theme)}
              </a>
            </div>
            <h5 className="text-3 mb-3 lan-text-right kfont">
              {translate("FOLLOW US")}
            </h5>
            <ul className="social-icons">
              <li className="social-icons-facebook">
                <a href={settings?.facebook} target="_blank" title="Facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="social-icons-twitter">
                <a href={settings?.twitter} target="_blank" title="Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="social-icons-instagram">
                <a href={settings?.instagram} target="_blank" title="instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="social-icons-youtube">
                <a href={settings?.youtube} target="_blank" title="instagram">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container py-2">
          <div className="row py-4">
            <div className="col-lg-8 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
              <a href="/" className="logo pr-1 ">
                <img
                  alt="Kurdish Talent"
                  width="60"
                  src="assets/images/logo_top.png"
                />
              </a>
              <p>© {translate("Copyright 2021. All Rights Reserved.")}</p>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end">
              <nav id="sub-menu">
                <ul>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <a href="faq" className="ml-1 text-decoration-none">
                      {" "}
                      {translate("FAQ")}
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <a href="blog" className="ml-1 text-decoration-none">
                      {" "}
                      {translate("Blog")}
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <a href="signin" className="ml-1 text-decoration-none">
                      {" "}
                      {translate("Sign Up")}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
