import React from 'react'
import { getDefaultValue } from '../../utils';
import LatestPosts from './LatestPosts'

export default function Intro({ translations }) {
	

	const translate = (str , def) => {
		if(def==null) def = str;
		if (!translations) return def;
		var translate = translations.find(x=>x.key === str );
	
		if (translate) return getDefaultValue(translate);
		else return "#" + str;
	  };
	  
		return (

			<div id="main" role="main" className="main">
				<div className="home-intro" id="home-intro">
					<div className="container">

						<div className="row align-items-center">
							<div className="col-lg-8">
								<p>
									{translate('Do you have a talent you want the world to see?')}
									<span>{translate('Don’t hesitate to register!')}</span>
								</p>
							</div>
							<div className="col-lg-4">
								<div className="get-started text-left text-lg-right">
									<a href="https://apps.apple.com/us/app/kurdish-talent/id1603624883" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg text-3 font-weight-semibold "><img src={'assets/images/apple.png'} alt="" style={{ width:130, }} /></a>
									&nbsp;&nbsp;
									<a href="https://play.google.com/store/apps/details?id=com.solutionscjit.kurdishtalent" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg text-3 font-weight-semibold "><img src={'assets/images/google.png'} alt="" style={{ width:130, }} /></a>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div className="container pt-4" id="main2">
					<div className="row">

						<div className="col-sm-6 col-md-6 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">

							<video controls width="100%" playsinline >
                                <source src="assets/video/register.mp4#t=1.5" type="video/mp4" />
                            </video>

							<div className="cascading-images-wrapper hide ">
								<div className="cascading-images position-relative">
									<div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
										<img src="assets/images/img-1.jpeg" className="appear-animation appear-animation-visible" width="600" alt="" data-appear-animation="expandIn" data-appear-animation-duration="600ms" />
									</div>
									<div className="position-absolute w-100 t4l3" >
										<div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.3, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
											<img src="assets/images/img-2.jpeg" className="appear-animation appear-animation-visible" width="600" alt="" data-appear-animation="expandIn" data-appear-animation-delay="300" data-appear-animation-duration="600ms" />
										</div>
									</div>
									<div className="position-absolute w-100 t7l3" >
										<div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.4, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
											<img src="assets/images/img-3.jpeg" className="appear-animation appear-animation-visible" width="600" alt="" data-appear-animation="expandIn" data-appear-animation-delay="600" data-appear-animation-duration="600ms" />
										</div>
									</div>
								</div>
							</div>


						</div>

						<div className="col col-md-6 col-lg-6 lan-rtl">
							<h4 className="mb-2 mt-5">{translate('How does it work?')}</h4>

							<div className="process process-vertical ">
								<div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">1</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-4 font-weight-bold">{translate('1_title','')}</h4>
										<p className="mb-0">{translate('1_desc','')}
										</p>
									</div>
								</div>
								<div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">2</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-4 font-weight-bold">{translate('2_title','')}</h4>
										<p className="mb-0">{translate('2_desc','')}</p>
									</div>
								</div>
								<div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">3</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-4 font-weight-bold">{translate('3_title','')}</h4>
										<p className="mb-0">{translate('3_desc','')}</p>
									</div>
								</div>
								<div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">4</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-4 font-weight-bold">{translate('4_title','')}</h4>
										<p className="mb-0">{translate('4_desc','')}</p>
									</div>
									
								</div>
								<div className="process-step appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
									<div className="process-step-circle">
										<strong className="process-step-circle-content ">5</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-4 font-weight-bold">{translate('5_title','')}</h4>
										<p className="mb-0">{translate('5_desc','')}</p>
									</div>

								</div>
							</div>

						</div>

					</div>

				</div>

				
				<LatestPosts translations={translations} />

			</div>

		)
}
