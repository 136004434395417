import React, { useState } from 'react'
import Modal from 'react-modal';
import { useStateValue } from '../../StateProvider';
import { translate } from '../../utils';
import UrlEmbed from '../UrlEmbed';

function CourseParticipantsTopTenCircle({participants}) {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modal_url, setModalUrl] = useState(null);
  const [modal_loading, setModalLoading] = useState(null);
  const [modal_artist, setModalArtist] = useState(null);
  const [{ translations }] = useStateValue();

  const PrepareModal = (course_participant) => {

    // console.log(course_participant)

    setModalLoading(true)
    setModalArtist(course_participant?.usertalent?.user?.profile)

    if(course_participant?.usertalentgallery && course_participant?.usertalentgallery.length>0){
        var usg = course_participant?.usertalentgallery[0]
        setModalUrl(usg.url)
    }
    setModalIsOpen(true)
    setModalLoading(false)


}



const closeModal = () => {
    setModalIsOpen(false)

}

  return (
    <>
        <div className="coursedocuments">
                    {participants && participants.map((item, index) => (
                        <div key={index} className="mb-3 ">
                            <div className="portfolio-item " 
                                >
                                <span className={`thumb-info  thumb-info-lighten circle-grandparent  `}>
                                    <span className="thumb-info thumb-info-centered-icons circle-topten ">
                                        <a href={`/${item.courseparticipant?.usertalent?.user.profile?.url ? item.courseparticipant?.usertalent?.user.profile.url : 'people/'+item.courseparticipant?.usertalent?.user.profile.id}`} className="thumb-info-wrapper tusi-border circle-topten ">
                                            {item.courseparticipant.usertalent.user.profile.thumb &&
                                                <img src={"https://kurdishtalent.com"+item.courseparticipant.usertalent.user.profile.thumb} className="document-user-img" alt={ item.courseparticipant.usertalent.user.profile.first_name} />
                                            }

                                            <span className="circle-artist-name ">
                                                { item.courseparticipant.usertalent.user.profile.first_name}
                                            </span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>

                    ))}
                </div>
                <div className="clear"></div>

                <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        <div className="modal-header height-75 lan-rtl">
                            <img className="rounded-circle user-logo" width="40" height="40" alt="" src={modal_artist && modal_artist?.avatar ? "https://kurdishtalent.com"+modal_artist.avatar : "/assets/images/user.png"}></img>
                            <h4 className="modal-title artist-name-rate lan-mr-5">{modal_artist && modal_artist?.first_name}</h4>
                            
                            <button type="button" className="close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body row">

                            <div className="col-md-12 file-container flex-center">
                                {modal_loading &&
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>
                                }
                                {modal_url && <UrlEmbed url={modal_url} />}
                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate(translations,'Close')}</button>
                        </div>
                    </div>
                </Modal>
    </>
  )
}

export default CourseParticipantsTopTenCircle