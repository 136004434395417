import React, {  useEffect } from "react";
import "./App.css";
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from "./utils";
import { useStateValue } from "./StateProvider";
import { loadTranslations } from "./utils/Network";
import MainRoutes from "./MainRoutes";

function App() {
  const [{ translations }, dispatch] = useStateValue();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {

    var language = urlParams.get("lang");
    
    if (language) {
      setCurrentLanguage(language);
      dispatch({
        type: "SET_CURRENT_LANGUAGE",
        language: language,
      });
    } else {
      language = getCurrentLanguage();
    //setCurrentLanguage(language);
      dispatch({
        type: "SET_CURRENT_LANGUAGE",
        language: language,
      });
    }

    loadTranslations().then((translationsss) => {
      dispatch({
        type: "SET_TRANSLATIONS",
        translations: translationsss,
      }
      
      
      );
    });

  }, []);

  return (
    <div>
      <MainRoutes translations={translations} />
    </div>
  );
}
export default App;
