import React, { useEffect, useState } from 'react'
import PostsCount from '../../../components/shared/PostsCount';
import TalentName from '../../../components/talent/TalentName';
import { useStateValue } from '../../../StateProvider';
import { BuildTalents, getDate, getDefaultName, getDefaultValue } from '../../../utils';
import { loadMenuTalents, loadPosts } from '../../../utils/Network';

export default function PostsContent({ id }) {

    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState(null);
    const [popular_posts, setPopularPosts] = useState(null);
    const [recent_posts, setRecentPosts] = useState(null);
    const [sidebar_talents, setSidebarTalents] = useState(null);
    const [show_menu, setShowingMenu] = useState(null);
    const [child_show_menu, setChildShowingMenu] = useState(null);
    


    useEffect(() => {
        setLoading(true)
        document.title = 'KurdishTalent | Blog '


        loadPosts(id).then(posts => {
            setPosts(posts)
            setPopularPosts(posts)
            setRecentPosts(posts)
            setLoading(false)
        })

        loadMenuTalents().then(talents => {
            setSidebarTalents(BuildTalents(talents))
        })
    }, [])

    const openMenu = (e, talent) => {
        if (talent.childs.length > 0) {
            e.preventDefault()
            setShowingMenu(talent.id)
            if (talent.id === show_menu)
                setShowingMenu(null)
        }
    }

    const openChildMenu = (e, talent) => {
        if (talent.childs.length > 0) {
            e.preventDefault()
            setChildShowingMenu(talent.id)
            if (talent.id === child_show_menu)
                setChildShowingMenu(null)
        }
    }

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };


    return (
        <div id="main" role="main" className="main">

            <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg  pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7  ">
                <div className="container">
                    <div className="row">



                        <div className="col-md-12 align-self-center order-1">


                            <ul className="breadcrumb d-block text-center lan-rtl">
                                <li><a href="" className="kfont">{translate('Home')}</a></li>
                                <li className={!id && "active kfont"}>{translate('Blog')}</li>
                                {id &&
                                    <li className="active kfont"><TalentName id={id} /></li>
                                }

                            </ul>
                        </div>

                        <div className="col order-3 appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="0" data-appear-animation-duration="1s" >
                            <hr className=" slide-line blackhr" />
                        </div>
                    </div>
                </div>

            </section>

            <div className="container py-4">
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>
                }

                {!loading &&
                    <div className="row">

                        <div className="col-lg-9 order-lg-1">
                            <div className="blog-posts">
                                {posts && posts.map(post => (
                                    <article className="post post-medium appear-animation animated fadeIn margin-right-10" >
                                        <div className="row mb-3">
                                            <div className="col-lg-5">
                                                <div className="post-image">
                                                    <a href={`post/${post.id}`}>
                                                        <img src={post.gallery_image ? post.gallery_image.image : "assets/images/default.png"} className="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0" alt={post.title} />
                                                </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="post-content padding-r-10">
                                                    <h2 className="font-weight-semibold pt-4 pt-lg-0 text-5 line-height-4 mb-2">
                                                        <a href={`post/${post.id}`} title={post.title}>
                                                            {post.title}
                                                        </a></h2>
                                                    <p className="mb-0 lan-rtl text-justify">{post.short_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="post-meta">
                                                    <span><i className="far fa-calendar-alt"></i> {getDate(post.updated_at)} </span>
                                                    <span><i className="far fa-user"></i> By <a href="#" className="kfont">{translate('Kurdish Talent')}</a> </span>
                                                    <span><i className="far fa-folder"></i> <a href={`blog/${post.talent}`} className="kfont">{post.talent && <TalentName id={post.talent} />} </a></span>
                                                    <span className="d-block d-sm-inline-block float-sm-right mt-3 mt-sm-0"><a href={`post/${post.id}`} className="btn btn-xs btn-light text-1 text-uppercase kfont">{translate("Read More")}</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </article>


                                ))}


                                <ul className="pagination float-right hide">
                                    <li className="page-item"><a className="page-link" href="#"><i className="fas fa-angle-left"></i></a></li>
                                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <a className="page-link" href="#"><i className="fas fa-angle-right"></i></a>
                                </ul>

                            </div>
                        </div>

                        <div className="col-lg-3 order-lg-2">
                            <aside className="sidebar">
                                <form action="page-search-results.html" method="get">
                                    <div className="input-group mb-3 pb-1 lan-rtl">
                                        <input className="form-control text-1 border-radius-0" placeholder={translate("Search...")} name="s" id="s" type="text" />
                                        <span className="input-group-append">
                                            <button type="submit" className="btn btn-primary border-1 text-1 p-2 "><i className="fas fa-search m-2"></i></button>
                                        </span>
                                    </div>
                                </form>
                                <h5 className="font-weight-semi-bold pt-4 kfont lan-text-right">{translate('Talents')}</h5>
                                <ul className="nav nav-list nav-list-rtl flex-column mb-5 lan-rtl">
                                    {sidebar_talents && sidebar_talents.map(talent => (
                                        <li key={talent.id} className="nav-item lan-text-right">
                                            <a className="nav-link" onClick={(e) => { openMenu(e, talent) }} href={`blog/${talent.id}`}>{getDefaultName(talent.names)} <PostsCount id={talent.id} /> </a>
                                            {talent.childs &&
                                                <ul className={show_menu == talent.id ? "show lan-rtl" : "hide lan-rtl"}>
                                                    {talent.childs.map(child => (
                                                        <li key={child.id} className="nav-item lan-text-right">
                                                            <a className="nav-link" href={`blog/${child.id}`} onClick={(e) => { openChildMenu(e, child) }}>{getDefaultName(child.names)} <PostsCount id={talent.id} /></a>
                                                            {child.childs &&
                                                                <ul className={child_show_menu == child.id ? "show" : "hide"}>
                                                                    {child.childs.map(grand_child => (
                                                                        <li key={grand_child.id} className="nav-item lan-text-right">
                                                                            <a className="nav-link" href={`blog/${grand_child.id}`}>{getDefaultName(grand_child.names)} <PostsCount id={talent.id} /></a>

                                                                        </li>

                                                                    ))}

                                                                </ul>
                                                            }
                                                        </li>

                                                    ))}

                                                </ul>
                                            }
                                        </li>
                                    ))}

                                </ul>
                                <div className="tabs tabs-dark mb-4 pb-2">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item active"><a className="nav-link show active text-1 font-weight-bold text-uppercase" href="#popularPosts" data-toggle="tab">{translate("Popular")}</a></li>
                                    <li className="nav-item"><a className="nav-link text-1 font-weight-bold text-uppercase" href="#recentPosts" data-toggle="tab">{translate("Recent")}</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="popularPosts">
                                            <ul className="simple-post-list">
                                                {popular_posts && popular_posts.map(post => (
                                                    <li className="lan-rtl">
                                                        <div className="post-image lan-float-right lan-ml-10 lan-mr-0">
                                                            <div className="img-thumbnail img-thumbnail-no-borders d-block ">
                                                                <a href={`post/${post.id}`} title={post.title}>
                                                                    <img src={post.image} width="50" height="50" alt={post.title} className="lan-float-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="post-info lan-rtl lan-text-right ">
                                                            <a href={`post/${post.id}`} className="lan-text-right kfont" title={post.title}>
                                                                {post.title}
                                                            </a>
                                                            <div className="post-meta">
                                                                {getDate(post.updated_at)}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="tab-pane" id="recentPosts">
                                            <ul className="simple-post-list">
                                                {recent_posts && recent_posts.map(post => (
                                                    <li key={post.id} className="lan-rtl">
                                                        <div className="post-image lan-float-right lan-ml-10 lan-mr-0">
                                                            <div className="img-thumbnail img-thumbnail-no-borders d-block ">
                                                                <a href={`post/${post.id}`} title={post.title}>
                                                                    <img src={post.image} width="50" height="50" alt={post.title} className="lan-float-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="post-info lan-rtl lan-text-right ">
                                                            <a href={`post/${post.id}`} className="lan-text-right kfont" title={post.title}>
                                                                {post.title}
                                                            </a>
                                                            <div className="post-meta">
                                                                {getDate(post.updated_at)}
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>

                    </div>

                }


            </div>

        </div>
    )

}
