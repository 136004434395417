import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import ProfileInformation from '../../../components/shared/ProfileInformation';
import { useStateValue } from '../../../StateProvider';
import { getCurrentLanguage, getDefaultName, getDefaultValue } from '../../../utils';
import { loadCourses } from '../../../utils/Network';

export default function DashboardAside({ profile }) {

  const [current_language, setCurrentLanguage] = useState(null);
  const [courses, setCourses] = useState(null);
  const [role, setRole] = useState("participant");

  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  useEffect(() => {
    setCurrentLanguage(getCurrentLanguage());

    _getData();

  }, []);

  const _getData = () => {
    loadCourses().then(courses => {
      setCourses(courses);
      // setLoading(false)

    });
  }

  return (
    <div className="col-lg-3 mt-4 mt-lg-0">
      <ProfileInformation />
      <aside className="sidebar mt-2" id="sidebar">
        <ul className="list list-icons list-primary list-borders text-2">
          {/* <li className="lan-text-right ">
                  <i
                    className={`fas fa-caret-${
                      current_language === "SO" ? "right" : "left"
                    } sidebar-list-style`}
                  ></i>{" "}
                  <strong className="text-color-primary lan-mr-25 kfont">
                    {translate("My Profile")}
                  </strong>
                </li> */}
          <li className="lan-text-right ">
            <i
              className={`fas fa-caret-right sidebar-list-style`}
            ></i>{" "}
            <strong className="text-color-primary lan-mr-25 kfont">
              <Link to="/dashboard">{translate("Dashboard")}</Link>
            </strong>
          </li>
          <li className="lan-text-right ">
            <i
              className={`fas fa-caret-right sidebar-list-style`}
            ></i>{" "}
            <strong className="text-color-primary lan-mr-25 kfont">
              <Link to="/mytalents">{translate("My Talent")}</Link>
            </strong>
          </li>
          <li className="lan-text-right ">
            <i
              className={`fas fa-caret-right sidebar-list-style`}
            ></i>{" "}
            <strong className="text-color-primary lan-mr-25 kfont">
              <Link to="profile">{translate("Manage Profile")}</Link>
            </strong>
          </li>
          <li className="lan-text-right ">
            <i
              className={`fas fa-caret-right sidebar-list-style`}
            ></i>{" "}
            <strong className="text-color-primary lan-mr-25 kfont">
              <Link to="contests">{translate("Contests")}</Link>
            </strong>
          </li>


          {role && role === "participant" && (
            <div className="competition-menu">
              <ul className="list list-icons list-primary list-borders text-2">
                {courses &&
                  courses.map((item) => (
                    <li key={item.id} className="courses-li">
                      <i className="fas fa-trophy color-orange margin-right-10 margin-left-20"></i>
                      <Link
                        to={`contests`}
                        className="color-orange margin-left-15 font-16 kfont"
                      >
                        {getDefaultName(item.names)}
                        {/* <CourseParticipated
                                course={item.id}
                                profile={profile && profile.id}
                              /> */}
                      </Link>
                    </li>
                  ))}
                {!courses && (
                  <li>
                    {translate(
                      "There are no current contests at the moment."
                    )}
                  </li>
                )}
              </ul>
            </div>
          )}

          {role === "participant" && (
            <li className="lan-text-right ">
              <i
                className={`fas fa-caret-right sidebar-list-style`}
              ></i>{" "}
              <strong className="text-color-primary lan-mr-25 kfont">
                <Link to="/support">{translate("Support")}</Link>
              </strong>
            </li>
          )}


        </ul>
      </aside>

    </div>

  )

}
