import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  BuildTalents,
  getCurrentLanguage,
  getDefaultName,
  getDefaultValue,
  getUser,
} from "../../utils";
import { logout } from "../../utils";
import {  setTheme,changeLanguage } from "../../utils";
import { loadMenuTalents, loadProfile } from "../../utils/Network";
import ResponsiveMenu from "../shared/ResponsiveMenu";
import LoginModal from "../user/LoginModal";

const Header = ({ translations }) => {
  const [profile, setProfile] = useState(null);
  const [menu_talents, setMenuTalents] = useState(null);
  const [show_mobile_menu, setShowMobileMenu] = useState(null);
  const [current_language, setPageLanguage] = useState(null);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

  const location = useLocation();
  if (location.pathname === "/signout") {
    logout();
  }
  const user = getUser();
  setTheme();

  useEffect(() => {
    loadProfile().then((profile) => {
      setProfile(profile);
    });
  }, []);

  useEffect(() => {
    setPageLanguage(getCurrentLanguage());
    loadMenuTalents().then((talents) => {
      setMenuTalents(BuildTalents(talents));
    });
  }, [translations]);

  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x=>x.key === str );

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  const openMobileMenu = () => {
    setShowMobileMenu(true);
  };

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <header
      id="header"
      className="header-transparent header-effect-shrink"
      data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': false, 'stickyEnableOnMobile': true, 'stickyStartAt': 70, 'stickyChangeLogo': false, 'stickyHeaderContainerHeight': 70}"
    >
      <div className="header-body border-top-0 bg-dark box-shadow-none overflow-visible">
        <div className="header-container container">
          <div className="header-row">
            <div className="header-column">
              <div className="header-row">
                <div className="header-logo">
                  <a href="/" className="">
                    <img
                      alt="Kurdish Talent"
                      width="150"
                      src="assets/images/logo_top.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="header-column justify-content-end">
              <div className="header-row">
                <div className="header-nav header-nav-line header-nav-bottom-line header-nav-bottom-line-no-transform header-nav-bottom-line-active-text-light header-nav-bottom-line-effect-1 header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
                  <div className="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-text-capitalize header-nav-main-text-size-2 header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                    <nav className="collapse">
                      <ul className="nav nav-pills" id="mainNav">
                        <li className="dropdown">
                          <a
                            className="dropdown-item dropdown-toggle"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            href="#talents"
                          >
                            {translate("Talents")}
                          </a>
                          <ul className="dropdown-menu">
                            {menu_talents &&
                              menu_talents.map((talent) => (
                                <span key={"t" + talent.id}>
                                  {talent.childs && talent.childs.length === 0 && (
                                    <li key={talent.id}>
                                      <a
                                        className="nav-link border-bottom-0 lan-bold"
                                        href={`talent/${talent.id}`}
                                      >
                                        {getDefaultName(talent.names)}
                                      </a>
                                    </li>
                                  )}
                                  {talent.childs && talent.childs.length > 0 && (
                                    <li className="dropdown-submenu">
                                      <a
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                        href={`#talent`}
                                      >
                                        {getDefaultName(talent.names)}
                                      </a>
                                      <ul className="dropdown-menu">
                                        {talent.childs.map(
                                          (child_menu_item) => (
                                            <li
                                              key={"c" + child_menu_item.id}
                                              className={
                                                child_menu_item.childs.length >
                                                0
                                                  ? "dropdown-submenu"
                                                  : ""
                                              }
                                            >
                                              <a
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  if (
                                                    child_menu_item.childs
                                                      .length > 0
                                                  )
                                                    e.preventDefault();
                                                }}
                                                href={
                                                  child_menu_item.childs
                                                    .length === 0
                                                    ? `talent/${child_menu_item.id}`
                                                    : "talent/"
                                                }
                                              >
                                                {getDefaultName(
                                                  child_menu_item.names
                                                )}
                                              </a>
                                              {child_menu_item.childs.length >
                                                0 && (
                                                <ul className="dropdown-menu">
                                                  {child_menu_item.childs.map(
                                                    (grandchild_menu_item) => (
                                                      <li
                                                        key={
                                                          "g" +
                                                          grandchild_menu_item.id
                                                        }
                                                      >
                                                        <a
                                                          className="dropdown-item"
                                                          href={`talent/${grandchild_menu_item.id}`}
                                                        >
                                                          {getDefaultName(
                                                            grandchild_menu_item.names
                                                          )}
                                                        </a>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </li>
                                  )}
                                </span>
                              ))}
                          </ul>
                        </li>

                        <li className="">
                          <a className="" href="faq">
                            {translate("FAQ")}
                          </a>
                        </li>

                        <li className="hide-on-small">
                          <a href="#spacer">|</a>
                        </li>

                        <li className="dropdown">
                          <a className="dropdown-item" href="/">
                            {translate("Home")}
                          </a>
                        </li>

                        <li>
                          <a className="" href="about">
                            {translate("About")}
                          </a>
                        </li>

                        {!user && (
                          <li className="dropdown">
                            <a className="dropdown-item " href="signin" onClick={(e)=>{
                              e.preventDefault();
                              setLoginModalIsOpen(true)
                              }} >
                              {translate("Sign Up")}
                            </a>
                          </li>
                        )}
                        {user && (
                          <li className="dropdown ">
                            <Link
                              to={profile && profile.role==="normal" ? "talent/6" : "dashboard" }
                              className="dropdown-item dropdown-toggle no-before"
                            >
                              <img
                                className="rounded-circle user-logo"
                                width="40"
                                height="40"
                                alt=""
                                src={
                                  profile && profile.image
                                    ? profile.image
                                    : "assets/images/user.png"
                                }
                              ></img>
                              {user &&
                                (user.first_name
                                  ? user.first_name + " " + user.last_name
                                  : user.email)}
                            </Link>
                            <ul className="dropdown-menu">
                              {profile && profile.role === "referee" && (
                                <li>
                                  <Link
                                    className="nav-link lan-text-right"
                                    to="referee"
                                  >
                                    {translate("Home")}
                                  </Link>
                                </li>
                              )}
                              {profile && profile.role === "participant" && (
                                <li>
                                  <Link
                                    className="nav-link lan-text-right"
                                    to="dashboard"
                                  >
                                    {translate("Dashboard")}
                                  </Link>
                                </li>
                              )}
                              {profile && profile.role === "participant" && (
                                <li>
                                  <Link
                                    className="nav-link lan-text-right"
                                    to="/mytalents"
                                  >
                                    {translate("My Talent")}
                                  </Link>
                                </li>
                              )}
                              {profile && profile.role === "participant" && (
                                <li>
                                  <Link
                                    className="nav-link lan-text-right"
                                    to="profile"
                                  >
                                    {translate("Manage Profile")}
                                  </Link>
                                </li>
                              )}
                              {profile && profile.role === "participant" && (
                                <li>
                                  <Link
                                    className="nav-link lan-text-right"
                                    to="contests"
                                  >
                                    {translate("Contests")}
                                  </Link>
                                </li>
                              )}

                              {profile && profile.role === "participant" && (
                                <li>
                                  <Link
                                    className="nav-link lan-text-right"
                                    to="support"
                                  >
                                    {translate("Support")}
                                  </Link>
                                </li>
                              )}

                              <li>
                                <Link
                                  className="nav-link lan-text-right border-bottom-0"
                                  to="signout"
                                >
                                  {translate("Log Out")}
                                </Link>
                              </li>
                            </ul>
                          </li>
                        )}

                        <li className="dropdown">
                          <a className="dropdown-item " href="signin">
                            <i className="fa fa-globe"></i>{" "}
                            <span
                              style={{
                                marginLeft: 5,
                                textTransform: "uppercase",
                              }}
                            >
                              {current_language === "so" && <>کوردی</>}
                              {current_language === "en" && <>English</>}
                              {current_language === "ku" && <>Kurdî</>}
                            </span>
                          </a>

                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="nav-link border-bottom-0"
                                onClick={(e) => {
                                  changeLanguage(e, "en");
                                }}
                                href={
                                  window.location.origin +
                                  window.location.pathname +
                                  `?lang=en`
                                }
                              >
                                English
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link border-bottom-0 always-kfont"
                                onClick={(e) => {
                                  changeLanguage(e, "so");
                                }}
                                href={
                                  window.location.origin +
                                  window.location.pathname +
                                  `?lang=so`
                                }
                              >
                                کوردی
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link border-bottom-0"
                                onClick={(e) => {
                                  changeLanguage(e, "ku");
                                }}
                                href={
                                  window.location.origin +
                                  window.location.pathname +
                                  `?lang=ku`
                                }
                              >
                                Kurdî
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  <button
                    className="btn header-btn-collapse-nav"
                    onClick={() => {
                      openMobileMenu();
                    }}
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} setLoggedIn={() => { }} isOpen={loginModalIsOpen} />

      <ResponsiveMenu
        current_language={current_language}
        talents={menu_talents}
        hideMenu={hideMobileMenu}
        user={user}
        profile={profile}
        show={show_mobile_menu}
      />
    </header>
  );
};
export default Header;
