import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from "html-react-parser";
import Modal from 'react-modal';

import { useStateValue } from '../StateProvider'
import { getDefaultName, getYoutubeId, isYoutube, translate, userAvatar, userImage } from '../utils'
import {ApiHttpService} from '../utils/http/api'
import UrlEmbed from '../components/UrlEmbed';

function People({profile}) {

    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [{ translations }] = useStateValue();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal_loading, setModalLoading] = useState(null);
    const [modal_url, setModalUrl] = useState(null);

    useEffect(() => {
        
        _getData()

    }, [])

    const _getData = ()=>{
        if(!id && !profile) return
        if(profile){
            document.title += ' | ' + profile?.first_name+" "+profile?.last_name;
            setData(profile)
        }
        else{
            setLoading(true)
            ApiHttpService.loadPublicProfile(id).then(data=>{
                if(data.id){
                    document.title += ' | ' + data?.first_name+" "+data?.last_name;
                    setData(data)
                }
                setLoading(false)
            })
        }
        
    }

    const PrepareModal = (usertalentgallery) => {
        setModalLoading(true)
        setModalUrl(usertalentgallery.url)
        setModalIsOpen(true)
        setModalLoading(false)
    }
    
    const closeModal = () => {
        setModalIsOpen(false)
    }
    
  return (
    <div>
                <div id="main" role="main" className="main">

                    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 "
                        style={{ backgroundSize: "cover", backgroundPositionX: "center", backgroundImage: true ? "url('assets/images/headerbg.jpeg')" :"" }} >
                        <div className="container ">
                            <div className="row lan-rtl">

                            <div className="col-md-12 align-self-center mb-20">
                                <ul className="breadcrumb d-block text-center lan-rtl">
                                    <li><a href="" className="kfont">{translate(translations,'Home')}</a></li>
                                    {/* <li className={!id && "active kfont"}>{translate(translations,'People')}</li> */}
                                    {data &&
                                        <li className="active kfont">{data?.first_name} {data?.last_name}</li>
                                    }

                                </ul>
                            </div>

                                <div className="col-md-7  text-left lan-text-right">
                                    <h1 className="font-weight-bold text-light mb-20 lan-text-right lan-full-width">
                                        {data?.first_name} {data?.last_name}
                                    </h1>
                                    <p>
                                        {getDefaultName(data?.country?.names)} {getDefaultName(data?.city?.names)}
                                    </p>
                                    <p className="">
                                        {parse(data?.bio ? data?.bio : "")}
                                    </p>

                                </div>
                                <div className="col-md-5">
                                {data?.image && (
                                    <span className={`thumb-info  thumb-info-lighten circle-big padding-5 `}>
                                    <span className="thumb-info thumb-info-centered-icons ">
                                        <span className="thumb-info-wrapper tusi-border circle-people ">
                                        <img src={userImage(data)} className="profile-big-img"  alt="" />
                                    </span>
                                    </span>
                                    </span>
                                )}
                                </div>
                            </div>
                        </div>
                        <div className="shape-divider shape-divider-bottom" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                                <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill="#212529" />
                            </svg>
                        </div>
                    </section>
                    
                    

                    <div className="container pb-2">
                        <div className="row kfont lan-rtl mt-3">
                            {data?.usertalentgallery && data.usertalentgallery.map(item=>(
                                <div key={item.id} onClick={() => { PrepareModal(item) }} className="col-4 col-sm-6 col-lg-4 mb-2  padding-x-5 ">
                                    <div className="portfolio-item">
                                            <span  className={`thumb-info  thumb-info-lighten thumb-info-no-borders border-radius-0 ${document && document.selected && "selected"} `}>
                                                <span className="thumb-info-wrapper upload-icon-container border-radius-0 ">
                                                {item && isYoutube(item.url) && (
                                                    <img
                                                    src={`https://img.youtube.com/vi/${getYoutubeId(
                                                        item.url
                                                    )}/0.jpg`}
                                                    className="img-fluid"
                                                    alt=""
                                                    />
                                                )}
                                                
                                                </span>
                                                <span className="infoicon font-size-40">
                                                    <i className="fab fa-youtube"></i>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>

                <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        <div className="modal-header height-75 lan-rtl">
                            <img className="rounded-circle user-logo" width="40" height="40" alt="" src={userAvatar(data)}></img>
                            <h4 className="modal-title artist-name-rate lan-mr-5">{data && data?.first_name}</h4>
                            
                            <button type="button" className="close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body row">

                            <div className="col-md-12 file-container flex-center">
                                {modal_loading &&
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>
                                }
                                {modal_url && <UrlEmbed url={modal_url} />}
                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate(translations,'Close')}</button>
                        </div>
                    </div>
                </Modal>

            </div>

            
  )
}

export default People