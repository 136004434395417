import React from 'react'
import SelectTalentsDialog from '../../pages/SelectTalentsDialog'


export default function SelectTalent({onselect}) {

  

    const handleSelect = (name,value) => {
        onselect(name,value)
    }


    return (
        <div className="row mb-2">
            <div className="col">
                <div className="flex">
                    <div className="form-group mb-1 talentselect-input ">
                        <div >
                            <SelectTalentsDialog onValue={handleSelect} />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )

}
