import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import RefereesCircles from '../../../components/contest/RefereesCircles'
import CourseParticipantsCircle from '../../../components/contest/CourseParticipantsCircle'
import CourseParticipantsTopTenCircle from '../../../components/contest/CourseParticipantsTopTenCircle'
import Modal from 'react-modal';

import parse from "html-react-parser";

import { useStateValue } from '../../../StateProvider'
import { getDefaultDescription, getDefaultName, getYoutubeId, isYoutube, translate } from '../../../utils'
import {ApiHttpService} from '../../../utils/http/api'
import UrlEmbed from '../../../components/UrlEmbed'
import CourseParticipantsFinalistCircles from '../../../components/contest/CourseParticipantsFinalistCircles'
import CourseParticipantsChampionCircles from '../../../components/contest/CourseParticipantsChampionCircles'
function CourseHistory({courseHistoryItem}) {

    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [course_history, setCourseHistory] = useState(null)
    const [{ translations }] = useStateValue();
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modal_loading, setModalLoading] = useState(null);
    const [modal_url, setModalUrl] = useState(null);

    useEffect(() => {
        
        _getData()

    }, [])

    const _getData = ()=>{
        if(!id && !courseHistoryItem) return

        if(courseHistoryItem){ // data loaded before and we use it here. ay chon :)
            setCourseHistory(courseHistoryItem)
            document.title = 'KurdishTalent | ' + getDefaultName(courseHistoryItem?.names)
        }
        else{ // Loading by id
            setLoading(true)
            ApiHttpService.loadCourseHistory(id).then(data=>{
                if(data.id){
                    setCourseHistory(data)
                }
                setLoading(false)
            })
        }
        
    }

    const PrepareModal = (coursehistorygallery) => {
        setModalLoading(true)
        setModalUrl(coursehistorygallery.url)
        setModalIsOpen(true)
        setModalLoading(false)
    }
    
    const closeModal = () => {
        setModalIsOpen(false)
    }

    
  return (
    <div>
                <div id="main" role="main" className="main">

                    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 "
                        style={{ backgroundSize: "cover", backgroundPositionX: "center", backgroundImage: true ? "url('assets/images/headerbg.jpeg')" :"" }} >
                        <div className="container ">
                            <div className="row lan-rtl">

                                <div className="col-md-7  text-left lan-text-right">
                                    <h1 className="font-weight-bold text-light mb-20 lan-text-right lan-full-width">
                                        {getDefaultName(course_history?.names)}
                                    </h1>
                                    <div className=" text-left lan-text-right">
                                        <div className="mb-2 mr-talent-desc text-justify text-talent-desc lan-text-right lan-mr-0">
                                            {getDefaultDescription(course_history?.names)}
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-5">
                                    <img src={course_history?.image} alt={course_history?.title} className="img-fluid" />
                                    {/* <TalentPosts key={this.state.talent} talent_id={this.state.talent && this.state.talent.id} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="shape-divider shape-divider-bottom" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                                <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill="#212529" />
                            </svg>
                        </div>
                    </section>
                    
                    

                    <div className="container pb-2">
                        <div className="row kfont lan-rtl mt-5">
                            <div className="col-md-12 flex justify-content-center">
                                <h3 className="center">{getDefaultName(course_history?.names,"referee_title")}</h3>
                                {/* <p className="lan-text-right">{parse(getDefaultName(course_history?.names,"referee_description"))}</p> */}
                            </div>
                            <div className="col-md-12 mb-5 flex justify-content-center">
                                <RefereesCircles referees={course_history && course_history?.referees} />
                            </div>                        
                        </div>
                        <div className="mt-30 kfont lan-rtl flex justify-content-center">
                            <h3 className="lan-text-right">{translate(translations,"Champion")}</h3>
                        </div>
                        <div className="kfont lan-rtl  mb-5 flex justify-content-center">
                            <CourseParticipantsChampionCircles participants={course_history && course_history?.coursehistorytalents.filter(item=>item.champion)} />
                        </div>
                        <div className="mt-30 kfont  lan-rtl flex justify-content-center">
                            <h3 className="lan-text-right">{translate(translations,"Final")}</h3>
                        
                        </div>
                        <div className=" flex  mb-5 justify-content-center">
                            <CourseParticipantsFinalistCircles participants={course_history && course_history?.coursehistorytalents.filter(item=>item.final)} />
                        
                        </div>
                        <div className="mt-30 kfont lan-rtl text-center">
                            <h3 className="text-center">{getDefaultName(course_history?.names,"top10_title")}</h3>
                            <p className="text-center">{parse(getDefaultName(course_history?.names,"top10_description"))}</p>
                        </div>
                        <div className="flex justify-content-center">
                            <CourseParticipantsTopTenCircle participants={course_history && course_history?.coursehistorytalents.filter(item=>item.topten)} />
                        
                        </div>
                        {/* <div className="mt-30 kfont lan-rtl">
                            <h3 className="lan-text-right" >{getDefaultName(course_history?.names,"top30_title")}</h3>
                            <p className="lan-text-right">{parse(getDefaultName(course_history?.names,"top30_description"))}</p>
                            <CourseParticipantsCircle participants={course_history && course_history?.coursehistorytalents.filter(item=>!item.topten and !item.champion and !item.final)} />
                        </div> */}

                        <hr className="my-4 slide-line" />
                        <div className="row kfont lan-rtl mt-5">
                            {/* <h3 className="lan-text-right">{translate(translations,"Episodes")}</h3> */}
                            <div className="col-md-12 row kfont lan-rtl">
                                {course_history && course_history?.coursehistorygalleries.map(item=>(
                                    <div key={item.id} onClick={() => { PrepareModal(item) }} className="col-4 col-sm-6 col-lg-4 mb-2  padding-x-5 ">
                                        <div className="portfolio-item">
                                                <span  className={`thumb-info  thumb-info-lighten thumb-info-no-borders border-radius-0 `}>
                                                    <span className="thumb-info-wrapper upload-icon-container border-radius-0 ">
                                                    {item && isYoutube(item.url) && (
                                                        <img
                                                        src={`https://img.youtube.com/vi/${getYoutubeId(
                                                            item.url
                                                        )}/0.jpg`}
                                                        className="img-fluid"
                                                        alt=""
                                                        />
                                                    )}
                                                    
                                                    </span>
                                                    <span className="infoicon font-size-40">
                                                        <i className="fab fa-youtube"></i>
                                                    </span>
                                                </span>
                                                <div className="text-center mt-2 mb-2">
                                                    {translate(translations,"Episode")} {item.episode}
                                                </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

                <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal "
                ariaHideApp={false}
                overlayClassName="Overlay"
                >
                    <div className="modal-content">
                        {/* <div className="modal-header height-75 lan-rtl">
                            <h4 className="modal-title artist-name-rate lan-mr-5">
                                {}
                            </h4>
                            
                            <button type="button" className="close" onClick={closeModal}></button>
                        </div> */}
                        <div className="modal-body row">

                            <div className="col-md-12 file-container flex-center">
                                {modal_loading &&
                                    <>
                                        <div className="bounce-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </>
                                }
                                {modal_url && <UrlEmbed url={modal_url} />}
                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light pull-right kfont rtl-pull-left" onClick={closeModal}>{translate(translations,'Close')}</button>
                        </div>
                    </div>
                </Modal>

            </div>
  )
}

export default CourseHistory