import React from 'react'
import { changeLanguage } from "../../utils";

function ChangeLanguagePanel({ current_language }) {
    return (
        <div className="col-lg-12 home-languages text-center">
            {current_language && current_language !== "en" && (
                <a href="?lang=en" onClick={(e) => { changeLanguage(e, "en") }} className="slide-link">
                    English
                </a>
            )}

            {current_language && current_language !== "ku" && (
                <a href="?lang=ku" onClick={(e) => { changeLanguage(e, "ku") }} className="slide-link ">
                    Kurdi
                </a>
            )}

            {current_language && current_language !== "so" && (
                <a href="?lang=so" onClick={(e) => { changeLanguage(e, "so") }} className="slide-link always-kfont">
                    کوردی
                </a>
            )}

        </div>
    )
}

export default ChangeLanguagePanel