import React, { useEffect } from 'react'
import AddUserTalentModal from '../../../components/dialogs/AddUserTalentModal';
import PageHeaderLight from '../../../components/shared/PageHeaderLight';
import WidgetAddDocument from '../../../components/widgets/WidgetAddDocument';
import { useStateValue } from '../../../StateProvider';
import { getDefaultValue } from '../../../utils';

import DashboardAside from './DashboardAside';


export default function Dashboard() {

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find((x) => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    useEffect(() => {

    }, [])

    return (
        <div>
            <div role="main" className="main">
                {/* <UserHeader /> */}
                <PageHeaderLight />
                <div className="container pt-3 pb-2">

                    <div className="row pt-2">
                        <DashboardAside />
                        <div className="col-lg-9">
                            <h2 className="font-weight-bold line-height-3 text-7 mb-0">{translate("Dashboard")}</h2>
                            <p className="mb-4 pb-2" >{translate("Welcome to KurdishTalent dashboard")}</p>
                            <hr className="my-5" />
                            <WidgetAddDocument />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}
