import React from 'react'

function LoadingOverlay({loading = false}) {
    return (
        <>
            {loading && (
            <div
                className="loading-overlay-showing"
                data-loading-overlay
                data-plugin-options="{'hideDelay': 500, 'effect': 'floatRings'}"
            >
                <div className="loading-overlay  bg-loading">
                    <div className="bounce-loader">
                        <div className="cssload-float-rings-loader">
                            <div className="cssload-float-rings-inner cssload-one"></div>
                            <div className="cssload-float-rings-inner cssload-two"></div>
                            <div className="cssload-float-rings-inner cssload-three"></div>
                        </div>
                    </div>
                </div>
            </div>
         )}
        </>
    )
}

export default LoadingOverlay