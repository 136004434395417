import React, { useEffect, useRef, useState } from 'react'
import { getDefaultName, getUser, isImage , isDocument, setUserSession, Mg, getDefaultDescription, getCurrentLanguage, getDefaultValue } from '../utils';
import { deleteUserTalent, doLogin, loadUserTalents } from '../utils/Network';
import UserTalentDocumentUpload from './userTalentDocumentUpload';
import Modal from 'react-modal';
import UrlEmbed from './UrlEmbed';
import HelpModal from './dialogs/HelpModal';
import { useStateValue } from "../StateProvider";
import ShowVideoModal from './dialogs/ShowVideoModal';
import NewestCourse from './contest/NewestCourse';


export default function UserTalents() {

    const [loading, setLoading] = useState(false);
    const CurrentUser = getUser()
    const [userTalents, setUserTalents] = useState(null);
    
    const [current_user_talent, setCurrentUserTalent] = useState(false);
    const [delete_loading, setDeleteLoading] = useState(false);
    const [modal_password, setModalPassword] = useState("");
    const [modal_error, setModalError] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [show_video_is_open, setShowVideoIsOpen] = useState(false);


    const [modal_file, setModalFile] = useState(false);
    const btnOpenModal = useRef(null);
    const btnCloseModal = useRef(null);
    const [current_language, setCurrentLanguage] = useState(null)

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    const openModal = (file_path, url) => {
        var file = {
            isImage: isImage(file_path),
            isDocument: isDocument(file_path),
            path: file_path,
            url: url
        }
        setModalFile(file)
        //btnOpenModal.current.click();
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)

    }

   

    

    const handleDelete = (talent_id) => {
        //e.preventDefault();

        if (talent_id)

            setCurrentUserTalent(talent_id)
    }

    const handleModalOnChange = (e) => {
        //e.preventDefault();
        switch (e.target.name) {
            case "modal_password":
                setModalError(false)
                setDeleteLoading(false)
                setModalPassword(e.target.value)
                break;
            default:
                break;
        }
    }



    const deleteCurrentUserTalent = (e) => {
        //e.preventDefault();

        setModalError(false)
        setDeleteLoading(true)

        if (modal_password.length < 6) {
            setModalError(translate("Please enter your password"))
            setDeleteLoading(false)
            return;
        }
        else {

            doLogin({ email: CurrentUser.email, password: modal_password }).then(data => {
                if (data.tokens) {

                    setUserSession(data.tokens.access, data);

                    deleteUserTalent(current_user_talent).then(data => {
                        setDeleteLoading(false)
                        //btnCloseModal.current.click()
                        closeModal()
                        window.location.href = "dashboard"
                        // loadUserTalents().then(usertalents => {
                        //     setUserTalents(usertalents)
                        // });
                    })

                }
                else {
                    setModalError(data.detail);
                }
                setDeleteLoading(false)
            });

            setDeleteLoading(true)


        }

    }


    useEffect(() => {
        setLoading(true)
        setCurrentLanguage(getCurrentLanguage())
        loadUserTalents().then(usertalents => {
            if (usertalents.length > 0) {
                var temp = usertalents.slice(0).reverse()
                setUserTalents(temp)
            }
            setLoading(false)

        });
    }, [])

    return (
        <>
            {
                (!loading && userTalents && userTalents.length === 0) &&
                <div >
                    <h2 className="font-weight-bold text-5 mb-0">
                        {translate('Welcome to Kurdish Talent')}
                    </h2>
                    {translate('you have to select your talents in order to continue.')}
                    <br />
                    {translate('Please choose at lease one talent from the list above')}

                </div>
            }
            {userTalents && userTalents.length > 0 &&
                <div className="">
                    <div className="tabs tabs-quaternary">
                        <ul className="nav nav-tabs ">
                            {userTalents && userTalents.map((item, index) => (
                                <li key={item.id} className={`nav-item `}>
                                    <a className={`nav-link ${index === 0 && "active"}`} href={"#talent" + item.id} data-toggle="tab">{getDefaultName(item.talent.names)} </a>
                                </li>

                            ))}
                        </ul>
                        <div className="tab-content">
                            <button ref={btnOpenModal} className="hide" data-toggle="modal" data-target="#defaultModal">Open modal</button>

                            {userTalents && userTalents.map((item, index) => {

                                return (

                                    <div key={item.id} id={`talent${item.id}`} className={`tab-pane ${index === 0 && "active"}`}>

                                        <div className="appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="600" data-plugin-options="{'accY': -150}">
                                            <div className="row lan-rtl">
                                                <div className="col-md-6 lan-text-right">
                                                    <h2 className="text-color-primary font-weight-normal text-5 mb-2">
                                                        {translate("Add Video")}
                                                    </h2>

                                                    <div className="font-color-grey">
                                                        {item.talent.documents && item.talent.documents.length > 0 && getDefaultDescription(item.talent.documents[0].names)}
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-md-6 padding-r-10">

                                                    <div className="my-1 text-right lan-text-left">
                                                        <a href="#" className="kfont" >
                                                            <img src="assets/images/tutorial_icon.png" style={{"width":40}} onClick={(e)=>{ e.preventDefault(); setShowVideoIsOpen(true) }} /> &nbsp;
                                                            
                                                        </a>
                                                    </div>
                                                    
                                                    <div className="row margin-0">
                                                        {item.talent.documents && item.talent.documents.length > 0 && Array(item.talent.documents[0].max_docs).fill(1).map((el, i) => (
                                                            <UserTalentDocumentUpload multiple={item.talent.documents[0].max_docs>1} key={i} index={i} openModal={openModal} usertalent={item} document_id={item.talent.documents && item.talent.documents.length > 0 && item.talent.documents[0].id}  max_file_size={item.talent.documents && item.talent.documents.length > 0 && item.talent.documents[0].max_file_size} />
                                                        ))
                                                        }
                                                    </div>

                                                </div>
                                                <div className="nwc">
                                                    <NewestCourse talent={item.talent.id} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <hr className="solid full-width" />
                                        <div className="full-width height-30">
                                            <a href="#delete" className="text-color-primary pull-right lan-float-left  kfont" onClick={() => { handleDelete(item.id) }} data-toggle="modal" data-target="#deleteModal">{translate('Delete this talent')}</a>
                                            {/* <a href="#help" className="text-color-primary pull-right lan-float-left kfont lan-ml-20 mr-5" data-toggle="modal" data-target="#helpModal">{translate('Need help ?')}</a> */}
                                            <a href="support" className="text-color-primary pull-right lan-float-left kfont lan-ml-20 mr-5">{translate('Support')}</a>
                                        </div>

                                        

                                    </div>



                                )
                            }
                            )}

                        </div>
                    </div>
                </div>
            }


            <Modal
                closeTimeoutMS={500}

                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal"
                overlayClassName="Overlay"
                ariaHideApp={false}
            >
                <div className="file-container">
                    {modal_file && modal_file.url && modal_file.url != "undefined" &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-hidden="true">&times;</button>

                            </div>
                            <div className="modal-body ">
                                <p className="text-color-primary">
                                    <UrlEmbed url={modal_file.url} />

                                </p>

                            </div>

                        </div>

                    }

                    {modal_file && (!modal_file.url || modal_file.url == "undefined") &&
                        <>
                            <button type="button" className="close modal-body-close" onClick={closeModal} data-dismiss="modal" aria-hidden="true">&times;</button>

                            {modal_file && modal_file.isImage &&
                                <img src={modal_file.path} className="img-fluid border-radius-0 " alt="" />
                            }
                            {modal_file && modal_file.isDocument &&
                                <UrlEmbed url={modal_file.path} isDocument={modal_file.isDocument} />
                            }
                            {modal_file && !modal_file.isImage && !modal_file.isDocument &&
                                <video key={modal_file.path} controls >
                                    <source src={modal_file.path} type="video/mp4" />
                                </video>
                            }
                            {!modal_file &&
                                <img src="assets/images/file_upload.png" className="upload-icon img-fluid border-radius-0  height-122" alt="" />
                            }
                        </>
                    }
                </div>

            </Modal>

            <div className="modal fade " id="deleteModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="defaultModalLabel">{translate('Delete Talent')}</h4>
                            <button type="button" ref={btnCloseModal} className="close lan-float-left lan-ml-0" data-dismiss="modal" aria-hidden="true">&times;</button>

                        </div>
                        <div className="modal-body lan-w-430">
                            <p className="text-color-primary lan-text-right">
                                {translate('to confirm delete, Please enter your password')}
                            </p>
                            <div className="form-group row">
                                <div className="col-sm-9 mb-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text lan-border-radius-r" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input type="password" name="modal_password" onChange={handleModalOnChange} placeholder={translate("Password")} className="form-control lan-border-radius-l  border-radius-25 text-3 h-auto py-2" />
                                    </div>

                                </div>
                                <div className="form-group col-sm-3 lan-padding-r-5">
                                    <input type="button" value={translate("Delete")} onClick={deleteCurrentUserTalent} className="btn rounded-35 btn-rash btn-modern float-right" />
                                </div>
                            </div>
                            {modal_error &&
                                <div className="alert alert-primary lan-rtl kfont lan-text-right">
                                    {modal_error}
                                </div>
                            }
                            {delete_loading &&
                                <div className="alert alert-primary kfont lan-text-right">
                                    {translate("please wait...")}
                                </div>
                            }
                            <div className="form-group row">

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <HelpModal />
            <ShowVideoModal isOpen={show_video_is_open} url={"https://kurdishtalent.com/assets/video/info_1.mp4"} setIsOpen={setShowVideoIsOpen} />

        </>
    )
}
