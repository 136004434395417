import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../StateProvider'
import { getDefaultDescription, getDefaultName, getDefaultValue, translate } from '../../utils'
import Constants from '../../utils/Constants'
import { ContestHttpService } from '../../utils/http/contest_http_service'
import ModalContestView from '../dialogs/ModalContestView'
import CompetitionMenu from './CompetitionMenu'
import ParticipateButtonSingle from './ParticipateButtonSingle'

function ContestParticipation({ talentId }) {

    const [contest, setContest] = useState(null)
    const [contestIsNotAvailable, setContestIsNotAvailable] = useState(false)
    const [contestViewModalIsOpen, setContestViewModalIsOpen] = useState(false)
    
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

    useEffect(() => {
        _getData()
    }, [])

    const _getData = () => {
        var query = "?talent=" + encodeURIComponent(talentId)
        ContestHttpService.loadContests(query).then((data) => {
            if (data.results) {
                setContest(data.results[0])
            }
            else {
                setContest(null)
                setContestIsNotAvailable(true)
            }
        });
    }
    

    return (
        <div>
            {contest && (
                <div className='flex-center relative '>
                    
                    <div className="contest-caption mb-2">
                        <h2>{getDefaultName(contest.names)}</h2>
                        {/* <CompetitionMenu talentId={talentId} key={talentId} id={talentId} enddate={contest.enddate}/> */}
                    </div>
                    <div className="contest-image-container mb-2">
                    <div className="contest-view-icon" onClick={()=>{ setContestViewModalIsOpen(true) }}  title={translate("View contest details")} >
                        <i className="fa fa-exclamation"></i>
                    </div>
                    <img src={contest.image} className="contest-image" alt="" />

                    </div>

                    {/* <div className="mt-2">
                        {getDefaultDescription(contest.names)}
                    </div> */}
                    <div className="mt-2 d-flex justify-content-center">
                        {contest && <ParticipateButtonSingle course={contest.id} /> }
                    </div>

                </div>
            )}

            {contestIsNotAvailable && (<>
                {Constants.ContestIsNotAvailableMessage}
            </>)}

            <ModalContestView isOpen={contestViewModalIsOpen} setIsOpen={setContestViewModalIsOpen} contest={contest} />

        </div>
    )
}

export default ContestParticipation