import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultValue } from "../../utils";

export default function ModalDeleteUserDocument({isOpen = false , setIsOpen , handleDocumentDelete}) {

  const closeModal = () => {
    setIsOpen(false);
  };

  
  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content">
        <div className="modal-body lan-text-right">
          <p className="text-color-primary">{translate('Are you sure you want to delete this document?')}</p>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-light pull-right lan-float-left mx-2 kfont" onClick={handleDocumentDelete}>{translate('Yes')}</button>
            <button type="button" className="btn btn-light pull-right lan-float-left kfont" onClick={closeModal}>{translate('No')}</button>
        </div>
      </div>
    </Modal>
  );
}
