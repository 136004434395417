import React from 'react'

function RefereesCircles({referees}) {

  return (
    <>
            <div className="coursedocuments">
                    {referees && referees.map((item, index) => (
                        <div key={index} className="mb-3 ">
                            <div className="portfolio-item " onClick={() => { 
                                //PrepareModal(item, item.courseparticipant.file, item.courseparticipant.url) 
                                }} >
                                <span className={`thumb-info  thumb-info-lighten circle-grandparent `}>
                                    <span className="thumb-info thumb-info-centered-icons ">
                                        <a href={item.referee.user.profile?.url ? item.referee.user.profile?.url : `people/${item.referee.user.profile.id}`} className="thumb-info-wrapper tusi-border circle-public ">

                                            {item.referee.user.profile.avatar &&
                                                <img src={"https://kurdishtalent.com"+item.referee.user.profile.avatar} className="document-user-img" alt="" />
                                            }

                                            <span className="circle-artist-name">
                                                { item.referee.user.profile.first_name}
                                            </span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>

                    ))}
                </div>
                <div className="clear"></div>
    </>
  )
}

export default RefereesCircles