import React, { useEffect, useRef, useState } from 'react'

import { deleteCourseParticipant, doLogin, loadCountry, loadCourses, loadProfile, loadUserTalents } from '../../../utils/Network'
import { getDefaultName, getDefaultValue, getUser, setUserSession } from '../../../utils';
import CompetitionMenu from '../../../components/contest/CompetitionMenu';
import ContestParticipate from '../../../components/contest/ParticipateButton';
import { useStateValue } from '../../../StateProvider';
import parse from 'html-react-parser'
import PageHeaderLight from '../../../components/shared/PageHeaderLight';
import DashboardAside from '../participant/DashboardAside';

export default function Competition() {

  const [loading, setLoading] = useState(null);
  const [courses, setCourses] = useState(null);
  const [usertalents, setUserTalents] = useState(null);
  const [usertalentscount, setUserTalentsCount] = useState(null);

  const [message, setMessage] = useState(false);
  const [modal_password, setModalPassword] = useState("");
  const [modal_error, setModalError] = useState(false);
  const [delete_loading, setDeleteLoading] = useState(false);
  const [selected_course, setSelectedCourse] = useState(false);
  const [selected_course_participant, setSelectedCourseParticipant] = useState(false);
  const CurrentUser = getUser()

  const btnCloseModal = useRef(null);

  const [current_language, setCurrentLanguage] = useState(null)

  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  const handleModalOnChange = (e) => {
    //e.preventDefault();
    switch (e.target.name) {
      case "modal_password":
        setModalError(false)
        setDeleteLoading(false)
        setModalPassword(e.target.value)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setLoading(true)

    loadUserTalents().then(usertalents => {
      setUserTalents(usertalents)
      setUserTalentsCount(usertalents.length)
    });

    loadCourses().then(courses => {
      setCourses(courses);
      setLoading(false)

    });



  }, [])

  const setCourseUserTalentId = (course_id, usertalent_id) => {
    if (courses) {
      var temp = courses;
      courses.map(course => {
        if (course.id === course_id) {
          course.usertalentId = usertalent_id;
        }
      })
      setCourses(temp)
    }
  }

  const unroll = (course, courseparticipant) => {

    setSelectedCourseParticipant(courseparticipant)
    setSelectedCourse(course)
  }

  const unRollCurrentCourse = () => {
    setModalError(false)
    setDeleteLoading(true)


    if (modal_password.length < 6) {
      setModalError(translate("Please enter your password"))
      setDeleteLoading(false)
      return;
    }
    else {

      doLogin({ email: CurrentUser.email, password: modal_password }).then(data => {
        if (data.tokens) {

          setUserSession(data.tokens.access, data);

          if (selected_course_participant) {
            //alert(selected_course_participant.id)
            deleteCourseParticipant(selected_course_participant.id).then(data => {
              setDeleteLoading(false)
              console.log(data)
              btnCloseModal.current.click()

              window.location.href = "contests"
              // loadUserTalents().then(usertalents => {
              //     setUserTalents(usertalents)
              // });
            })
          }


        }
        else {
          setModalError(data.detail);
        }
        setDeleteLoading(false)
      });

      setDeleteLoading(false)


    }
  }


  return (
    <div>
      <div role="main" className="main">
        <PageHeaderLight />
        <div className="container pt-3 pb-2">


          <div className="row pt-2 lan-rtl">
            <DashboardAside />
            <div className="col-lg-9">
              <h2 className="font-weight-bold line-height-3 text-7 mb-0">
                {translate("Contests")}
              </h2>
              <p className="mb-4 pb-2 lan-text-right">
                {translate("You can view newest contests here")}
              </p>
              {courses && courses.length == 0 && <>
                <>{parse(translate('There is no contest available now.'))}</>
              </>}
              {courses && courses.map((item) => (
                <section
                  key={item.id}
                  className="section  py-2  m-0 border-0 border-top-primary mb-4 brb-5"
                >
                  <div className="container ">
                    <div className="row align-items-center justify-content-center">
                      <div className="col">
                        <div className="row mb-4 px-2 mb-0">

                          <div className="col-md-12 px-0 ">
                            <h2 className=" font-weight-normal text-6 mb-0 title-cont lan-text-right ">
                              {getDefaultName(item.names)}
                            </h2>
                            <CompetitionMenu
                              id={item.talent}
                              country_id={item.country}
                              enddate={item.enddate}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 px-0 pb-3">
                          <img src={item.image} alt="" className="contest-image" />
                          <ContestParticipate
                            key={usertalentscount}
                            user_id={CurrentUser.id}
                            unroll={unroll}
                            setParentUserTalentId={setCourseUserTalentId}
                            usertalents={usertalents}
                            setMessage={setMessage}
                            course_id={item.id}
                            course={item}
                            talent_id={item.talent}
                          />

                          {message && message == item.id && (
                            <div className="alert kfont  alert-primary lan-text-right">
                              <i className="fa fa-check color-green"></i>
                              {parse(translate(
                                "You successfully participated in this competiotion."
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog lan-rtl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="defaultModalLabel">
                {translate("Unroll from")}{" "}
                {getDefaultName(selected_course.names)}
              </h4>
              <button
                type="button"
                ref={btnCloseModal}
                className="close lan-float-left lan-ml-0"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <p className="text-color-primary lan-text-right">
                {translate("to confirm , Please enter your password")}
              </p>
              <div className="form-group row">
                <div className="col-lg-8">
                  <div className="input-group">
                    <div className="input-group-prepend ">
                      <span
                        className="input-group-text lan-border-radius-r"
                        id="inputGroup-sizing-default"
                      >
                        <i className="fa fa-lock"></i>
                      </span>
                    </div>
                    <input
                      type="password"
                      name="modal_password"
                      onChange={handleModalOnChange}
                      placeholder="Password"
                      className="form-control lan-ltr border-radius-25 lan-border-radius-l text-3 h-auto py-2"
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3 lan-padding-r-0">
                  <input
                    type="button"
                    value={translate("Unroll")}
                    onClick={unRollCurrentCourse}
                    className="btn rounded-35 btn-rash btn-modern float-right"
                  />
                </div>
              </div>
              {modal_error && (
                <div className="alert alert-primary">{modal_error}</div>
              )}
              {delete_loading && (
                <div className="alert alert-primary">please wait...</div>
              )}
              <div className="form-group row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
