import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultName, getDefaultValue } from "../../utils";
import parse from 'html-react-parser'

export default function ModalContestSuggestion({isOpen = false , course, setIsOpen }) {

  const closeModal = () => {
    setIsOpen(false);
  };

  
  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content">
        <div className="modal-body lan-text-right">
          <p className="text-color-primary">
              <div className="mb-3 lan-rtl">
                <img src={course && course.image} className="full-width" alt="" />
                <h3 className="mt-3">{course && getDefaultName(course.names)}</h3>
                {parse(translate('now you can participate in a contest'))}
              </div>
              <a href="/contests" className="btn btn-primary width-200  pull-right rtl-pull-left" type="button" >
                {translate('Go to contest')}
              </a>
              <a href="#" onClick={(e)=>{ e.preventDefault(); closeModal() }} className="btn  width-200  pull-right rtl-pull-left" type="button" >
                {translate('No')}
              </a> &nbsp;&nbsp;
              
          </p>
        </div>
        
      </div>
    </Modal>
  );
}
