import React, {  useEffect, useState } from "react";
import Intro from "../../components/shared/Intro";
import { getCurrentLanguage, getDefaultValue, setUserSession } from "../../utils";

import "./Home.css";
import validator from "validator";
import { ApiBase, loadPopupMessages } from "../../utils/Network";
import { useStateValue } from "../../StateProvider";
import Cookie from "../../components/base/Cookie";
import ChangeLanguagePanel from "../../components/base/ChangeLanguagePanel";
import ModalPopupMessage from "../../components/dialogs/ModalPopupMessage";
import LoginModal from "../../components/user/LoginModal";

export default function Home() {
  
  const [{ translations }] = useStateValue();
  const [current_language, setCurrentLanguage] = useState(null);
  const [modalPopupIsOpen, setModalPopupIsOpen] = useState(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);
  
  useEffect(() => {
    setCurrentLanguage(getCurrentLanguage());
    loadPopupMessages().then(popupmessages=>{
      if(popupmessages && popupmessages.length>0){
        setPopupMessage(popupmessages[0])
        setModalPopupIsOpen(true)
      }
    })  
  }, []);

  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    
    setError(null);
    setLoading(true);

    if (!validator.isEmail(username.value)) {
      setError(translate("Email address is not valid"));
      setLoading(false);
      return;
    }

    if (!validator.isLength(password.value, { min: 6 })) {
      setError(translate("Password is not valid"));
      setLoading(false);
      return;
    }

    fetch(`${ApiBase}auth/login/`, {
      headers: new Headers(headers),
      method: "POST",
      body: JSON.stringify({
        email: username.value,
        password: password.value,
        //     email: 'jhiyar1@gmail.com',
        //    password: '123456asdasd'
      }),
    })
      .then((response) => {
        setLoading(false);
        var responsejson = response.json();
        if (response.status >= 400) {
          setError(translate("Wrong username or password"));
        }
        return responsejson;
      })
      .then(function (data) {
        if (data.tokens) {
          setUserSession(data.tokens.access, data);

          if (data.role === "referee") window.location.href = "referee";
          if (data.role === "normal") window.location.href = "talent";
          else window.location.href = "dashboard";
        } else {
          setError(translate("Wrong username or password"));
        }
      });
  };

  return (
    <div>
      <Cookie translations={translations} />
      <LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} setLoggedIn={() => { }} isOpen={loginModalIsOpen} />

      <section
        className="section border-0 video overlay overlay-show overlay-op-8 m-0 height-100vh"
        data-video-path="assets/video/kt"
        data-plugin-video-background
        data-plugin-options="{'posterType': 'jpg', 'position': '50% 50%'}"
      >
        <div className="container position-relative z-index-3 h-100">
          <div className="row align-items-center h-100">
            <div className="col">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <h1
                  className="position-relative text-color-light text-5 line-height-5 font-weight-medium px-4 mb-2 appear-animation"
                  data-appear-animation="fadeInDownShorterPlus"
                  data-plugin-options="{'minWindowWidth': 0}"
                >
                  <span className="position-absolute right-100pct top-50pct transform3dy-n50 opacity-3">
                    <img
                      src="assets/img/slides/slide-title-border-light.png"
                      className="w-auto appear-animation"
                      data-appear-animation="fadeInRightShorter"
                      data-appear-animation-delay="250"
                      data-plugin-options="{'minWindowWidth': 0}"
                      alt=""
                    />
                  </span>
                  {translate("Welcome To")}{" "}
                  <span className="position-relative">
                    <span className="position-absolute left-50pct transform3dx-n50 top-0 mt-3">
                      <img
                        src="img/slides/slide-blue-line.png"
                        className="w-auto appear-animation"
                        data-appear-animation="fadeInLeftShorterPlus"
                        data-appear-animation-delay="1000"
                        data-plugin-options="{'minWindowWidth': 0}"
                        alt=""
                      />
                    </span>
                  </span>
                  <span className="position-absolute left-100pct top-50pct transform3dy-n50 opacity-3">
                    <img
                      src="assets/img/slides/slide-title-border-light.png"
                      className="w-auto appear-animation"
                      data-appear-animation="fadeInLeftShorter"
                      data-appear-animation-delay="250"
                      data-plugin-options="{'minWindowWidth': 0}"
                      alt=""
                    />
                  </span>
                </h1>
                <h1
                  className="text-color-light font-weight-extra-bold text-12 line-height-1 mb-2 appear-animation"
                  data-appear-animation="blurIn"
                  data-appear-animation-delay="1000"
                  data-plugin-options="{'minWindowWidth': 0}"
                >
                  {translate("Kurdish Talent")}
                </h1>
                <p
                  className="text-4 text-color-light font-weight-light opacity-7 mb-0"
                  data-plugin-animated-letters
                  data-plugin-options="{'startDelay': 2000, 'minWindowWidth': 0}"
                >
                  {current_language && current_language === "so" && (
                    <>پێگەیەک بۆ ناساندن و بەرزنرخاندنی بەهرەکانی تۆ</>
                  )}
                  {current_language && current_language === "ku" && (
                    <>Platformek ji bo nasandin û nirxandina zîrektîyên te</>
                  )}
                  {(!current_language || current_language === "en") && (
                    <>A platform for talents to thrive</>
                  )}
                </p>
              </div>
              <ChangeLanguagePanel current_language={current_language} />

              <div
                className="col appear-animation animated fadeInUp appear-animation-visible"
                data-appear-animation="fadeInUp"
                data-appear-animation-delay="0"
                data-appear-animation-duration="1s"
              >
                <hr className="my-1 slide-line" />
              </div>
              <div
                className="d-flex flex-row align-items-center justify-content-center appear-animation animated fadeIn appear-animation-visible"
                data-appear-animation="fadeIn"
                data-appear-animation-delay="1s"
                data-appear-animation-duration="3s"
              >
                <form
                  className="form-row slide-form form-fields-rounded "
                  onSubmit={handleLogin}
                >
                  <div className="form-group col-lg-5">
                    <input
                      type="email"
                      {...username}
                      placeholder={translate("Email")}
                      maxLength="100"
                      className="form-control text-3 h-auto py-2 text"
                      name="username"
                      required=""
                    />
                  </div>
                  <div className="form-group col-lg-5 col-sm-7">
                    <input
                      type="password"
                      {...password}
                      placeholder={translate("Password")}
                      data-msg-email={translate("Please enter a valid email address")}
                      maxLength="100"
                      className="form-control text-3 h-auto py-2 text"
                      name="password"
                      required=""
                    />
                  </div>
                  <div className="form-group col-lg-2 col-sm-7">
                    <button
                      type="submit"
                      className="btn btn-dark  btn-block text-uppercase rounded-35 font-weight-bold text-3 "
                      data-loading-text="Loading..."
                    >
                      {translate("Login")}
                    </button>
                  </div>
                  {error && (
                    <div className="alert alert-danger text-2 ">{error}</div>
                  )}
                  {loading && (
                    <small className="loading">
                      {translate("Please Wait")}
                    </small>
                  )}
                  <div className="col-lg-12">
                    <a href="forgot" className="slide-link link-first ">
                      {translate("Forgot Password ?")}
                    </a>
                    <a href="#signin" onClick={(e)=>{ e.preventDefault(); setLoginModalIsOpen(true);  }}   className="slide-link">
                      {translate("Sign Up")}
                    </a>
                  </div>
                </form>
              </div>
              
              <a href="/kurdishtalent-2022">
              <img
                      src="assets/images/season01.png"
                      className="home-history-img appear-animation"
                      data-appear-animation="fadeInRightShorter"
                      data-appear-animation-delay="260"
                      data-plugin-options="{'minWindowWidth': 0}"
                      alt=""
                    />
              </a>

            </div>
          </div>
          <a
            href="#main"
            className="slider-scroll-button position-absolute left-50pct transform3dx-n50"
            data-hash
            data-hash-offset="80"
          >
            Sroll To Bottom
          </a>
        </div>
      </section>
      <ModalPopupMessage popupMessage={popupMessage} isOpen={modalPopupIsOpen} setIsOpen={setModalPopupIsOpen} />
      <Intro translations={translations} />
    </div>
  );
}
