import React, { useState } from "react";
import Modal from "react-modal";
import { useStateValue } from "../../StateProvider";
import { getDefaultValue } from "../../utils";
import parse from 'html-react-parser'

export default function ModalRules({isOpen = false , setIsOpen }) {

  const closeModal = () => {
    setIsOpen(false);
  };

  
  const [{ translations }] = useStateValue();
  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find((x) => x.key == str);

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
    >
      <div className="modal-content">
      <div className="modal-header ">
            <h4 className="modal-title lan-text-right lan-rtl" id="defaultModalLabel">{translate('Our terms and conditions')}</h4>
            <button type="button" className="close lan-float-left lan-ml-0" onClick={closeModal}>&times;</button>
        </div>
        <div className="modal-body lan-text-right">
          <p className="text-color-primary">{parse(translate('site_rules'))}</p>
        </div>
        
      </div>
    </Modal>
  );
}
