import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { getDefaultValue, sortByKey } from '../../../utils';
import { loadCountries, loadProfile, saveProfile } from '../../../utils/Network';
import SelectCities from '../../select/SelectCities';
import SelectDay from '../../select/SelectDay';
import SelectGender from '../../select/SelectGender';
import SelectMonth from '../../select/SelectMonth';
import SelectYear from '../../select/SelectYear';
import SelectCountries from "../../select/SelectCountries";

function ProfileForm(props) {
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState(null);
    const [birth_year, setBirthYear] = useState(null);
    const [birth_month, setBirthMonth] = useState(null);
    const [birth_day, setBirthDay] = useState(null);
    const location = useLocation();
    const [country, setCountry] = useState(null);
    const [city, setCity] = useState(null);
    const [livingcountry, setLivingCountry] = useState(null);
    const [livingcity, setLivingCity] = useState(null);
    const [gender, setGender] = useState(null);

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    useEffect(() => {
        setLoading(true);
        loadProfile().then((profile) => {
            setUser(profile);
            setLoading(false);
            if (profile) {
                if (profile.role === "normal") {
                    window.location.href = "/talent/6"
                }

                if (profile.birth_date) {
                    var birthdate = profile.birth_date.split("-");
                    setBirthYear(birthdate[0]);
                    setBirthMonth(birthdate[1]);
                    setBirthDay(birthdate[2]);
                }
                if (profile.country_id) {
                    setCountry(profile.country_id);
                }
                if (profile.city_id) {
                    setCity(profile.city_id);
                }
                if (profile.livingcountry_id) {
                    setLivingCountry(profile.livingcountry_id);
                }
                if (profile.livingcity_id) {
                    setLivingCity(profile.livingcity_id);
                }
                if (profile.gender > -1) {
                    setGender(profile.gender);
                }

            }
        });
        loadCountries(1, 10000).then((countries) => {
            sortByKey(countries);
            setCountries(countries);
        });
    }, []);


    const onValue = (name, value) => {
        var tu = user;
        switch (name) {
            case "birth_year":
                var date = [2021, 1, 1];
                if (user.birth_date) date = user.birth_date.split("-");
                date[0] = value;
                tu.birth_date = date[0] + "-" + date[1] + "-" + date[2];
                setUser(tu);
                setBirthYear(value);
                break;
            case "month":
                var date = [2021, 1, 1];
                setBirthMonth(value);
                if (user.birth_date) date = user.birth_date.split("-");
                date[1] = value;
                tu.birth_date = date[0] + "-" + date[1] + "-" + date[2];
                setUser(tu);
                setBirthMonth(value);
                break;
            case "day":
                var date = [2021, 1, 1];
                setBirthDay(value);
                if (user.birth_date) date = user.birth_date.split("-");
                date[2] = value;
                tu.birth_date = date[0] + "-" + date[1] + "-" + date[2];
                setUser(tu);
                break;
            case "gender":
                setGender(value);
                break;
            case "country":
                tu.country_id = value;
                setCountry(value);
                setUser(tu);
                break;
            case "city":
                tu.city_id = value;
                setCity(value);
                break;
            case "living_country":
                tu.livingcountry_id = value;
                setLivingCountry(value);
                setUser(tu);
                break;
            case "living_city":
                tu.livingcity_id = value;
                setLivingCity(value);
                setUser(tu);
                break;
            default:
                break;
        }
    };


    const handleOnChange = (e) => {
        var el = e.target;
        var tu = user;
        switch (el.name) {
            case "first_name":
                tu.first_name = el.value;
                setUser(tu);
                break;
            case "last_name":
                tu.last_name = el.value;
                setUser(tu);
                break;
            case "gender":
                setGender(el.value);
                break;
            case "phone_number":
                tu.phone_number = el.value;
                setUser(tu);
                break;

            case "password":
                tu.password = el.value;
                setUser(tu);
                break;
            case "confirmpassword":
                tu.confirmpassword = el.value;
                setUser(tu);
                break;
            case "website":
                tu.website = el.value;
                setUser(tu);
                break;
            case "facebook":
                tu.facebook = el.value;
                setUser(tu);
                break;
            case "instagram":
                tu.instagram = el.value;
                setUser(tu);
                break;
            case "youtube":
                tu.youtube = el.value;
                setUser(tu);
                break;
            case "snapchat":
                tu.snapchat = el.value;
                setUser(tu);
                break;
            case "spotify":
                tu.spotify = el.value;
                setUser(tu);
                break;
            case "comments":
                tu.comments = el.value;
                setUser(tu);
                break;

            default:
                break;
        }
    };

    const saveChanges = (e) => {
        setLoading(true);
        var temp = user;
        temp.gender = gender;
        temp.country_id = country;
        temp.city_id = city;
        temp.livingcountry_id = livingcountry;
        temp.livingcity_id = livingcity;

        saveProfile(temp).then((data) => {
            console.log(data);
            if(data.id){
                if(props.onSuccess){
                    props.onSuccess()
                }
            }
            setLoading(false);
        });
    };

    return (
        <>
            <form className="needs-validation styled">
                <div className="row">
                    <div className="col-md-6 mb-2">
                                <input
                                    className="form-control text-3 h-auto py-2 rounded-35"
                                    type="text"
                                    name="first_name"
                                    defaultValue={user && user.first_name}
                                    onChange={handleOnChange}
                                    placeholder={translate("Name")}
                                />
                    </div>
                    <div className="col-md-6 mb-2">
                                <input
                                    className="form-control text-3 h-auto py-2 rounded-35"
                                    type="text"
                                    name="last_name"
                                    defaultValue={user && user.last_name}
                                    onChange={handleOnChange}
                                    placeholder={translate("Surename")}
                                />
                            </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 mb-3">
                        <SelectGender
                            placeholder={translate("Gender")}
                            value={gender}
                            callback={onValue}
                        />
                    </div>
                    <div className="col-lg-9">
                        <input
                            className="form-control text-3 h-auto py-2 rounded-35"
                            type="phone"
                            name="phone_number"
                            onChange={handleOnChange}
                            defaultValue={user && user.phone_number}
                            placeholder={translate("Phone Number")}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <label className=" col-lg-12 lan-text-right">
                        {translate("Date of Birth")}
                    </label>
                    <div className="form-group col-lg-4">
                        <div className="custom-select-1">
                            <SelectYear
                                placeholder={translate("Year")}
                                name="birth_year"
                                width="100"
                                value={birth_year}
                                callback={onValue}
                            />
                        </div>
                    </div>
                    <div className="form-group col-lg-4">
                        <div className="custom-select-1">
                            <SelectMonth
                                placeholder={translate("Month")}
                                name="month"
                                width="100"
                                value={birth_month}
                                callback={onValue}
                            />
                        </div>
                    </div>
                    <div className="form-group col-lg-4">
                        <div className="custom-select-1">
                            <SelectDay
                                placeholder={translate("Day")}
                                name="day"
                                width="100"
                                value={birth_day}
                                callback={onValue}
                            />
                        </div>
                    </div>
                </div>

                <label className="lan-text-right full-width">
                    {translate("I am originally from")}
                </label>
                <div className=" row">
                    <div className="col-lg-6 mb-3">
                        <SelectCountries
                            placeholder={translate("Countries")}
                            kurds={1}
                            callback={onValue}
                            value={country}
                            name="country"
                        />
                    </div>
                    <div className="col-lg-6">
                        <SelectCities
                            placeholder={translate("City")}
                            key={country}
                            country={country}
                            name="city"
                            value={city}
                            callback={onValue}
                        />
                    </div>
                </div>
                <label className="lan-text-right full-width">
                    {translate("I am living in")}
                </label>
                <div className="form-group row">
                    <div className="col-lg-6 mb-3">
                        <SelectCountries
                            placeholder={translate("Countries")}
                            kurdsOnTop={1}
                            callback={onValue}
                            value={livingcountry}
                            name="living_country"
                        />
                    </div>
                    <div className="col-lg-6">
                        <SelectCities
                            placeholder={translate("Living City")}
                            key={livingcountry}
                            country={livingcountry}
                            value={livingcity}
                            name="living_city"
                            callback={onValue}
                        />
                    </div>
                </div>

                <div className=" row">
                    <div className=" col-lg-9"></div>
                    <div className="col-lg-3">
                        <input
                            type="button"
                            value={translate("Save Changes")}
                            className="btn rounded-35 btn-rash btn-modern float-right lan-float-left"
                            disabled={loading}
                            onClick={saveChanges}
                        />
                    </div>
                </div>
            </form>
            {loading && (
                <div className="alert alert-primary">Please Wait...</div>
            )}
        </>
    )
}

export default ProfileForm