import React, { Component} from 'react'
import VideoPanel from './VideoPanel';
import { getDefaultDescription, getDefaultName } from '../utils';
import TalentPosts from '../components/TalentPosts';

//loadProfile();

export default class Videos extends Component {


    constructor(props) {
        super(props);
        this.state = { talent: null };
        
    }

    render() {
        let id = this.props.match.params.id;

        const callback = (talent) => {
            this.setState({ talent: talent });
        }

        return (
            <div>
                <div id="main" role="main" className="main">

                    <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 "
                        style={{ backgroundSize: "cover", backgroundPositionX: "center", backgroundImage: this.state.talent && this.state.talent.header_url ? "url('" + this.state.talent.header_url + "')" :"" }} >
                        <div className="container ">
                            <div className="row lan-rtl">

                                <div className="col-md-7  text-left lan-text-right">
                                    <h1 className="font-weight-bold text-light mb-20 lan-text-right lan-full-width">
                                        {this.state.talent ? getDefaultName(this.state.talent.names) : ""}
                                    </h1>
                                    <div className=" text-left lan-text-right">
                                        <div className="mb-2 mr-talent-desc text-justify text-talent-desc lan-text-right lan-mr-0">
                                            {this.state.talent ? getDefaultDescription(this.state.talent.names) : ""}
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-5">
                                    {/* <video controls poster="assets/video/howtovote.jpg"  width="100%" height="250">
                                        <source src="assets/video/ratinghelp.mp4" type="video/mp4" />
                                    </video> */}
                                    <a href="/kurdishtalent-2022">
                                        <img src="assets/images/history.png" alt="Contest History" className="img-fluid " />
                                    </a>
                                    <TalentPosts key={this.state.talent} talent_id={this.state.talent && this.state.talent.id} />
                                </div>
                            </div>
                        </div>
                        <div className="shape-divider shape-divider-bottom" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 136" preserveAspectRatio="xMinYMin">
                                <path d="M 0 0.11 L 0 136 L 1920 136 L 1920 1.9 C 1650.0482 79.0955 1327.0648 134 980 134 C 615.6218 134 277.7878 84.5059 0 0.11 Z" fill="#212529" />
                            </svg>
                        </div>
                    </section>
                    
                    

                    <div className="container pb-2">

                        <VideoPanel talent_id={id} callback={callback} />

                    </div>

                </div>

            </div>
        )

    }
}
