import React, { useState } from 'react'
import Modal from 'react-modal';
import { useStateValue } from '../../StateProvider';

function CourseParticipantsChampionCircles({participants}) {

  const [{ translations }] = useStateValue();

  return (
    <>
        <div className="coursedocuments">
                    {participants && participants.map((item, index) => (
                        <div key={index} className="mb-3 ">
                            <div className="portfolio-item " 
                                >
                                <span className={`thumb-info  thumb-info-lighten circle-grandparent  `}>
                                    <span className="thumb-info thumb-info-centered-icons circle-topten ">
                                        <a href={`/${item.courseparticipant?.usertalent?.user.profile?.url ? item.courseparticipant?.usertalent?.user.profile.url : 'people/'+item.courseparticipant?.usertalent?.user.profile.id}`} className="thumb-info-wrapper tusi-border circle-topten ">
                                            {item.courseparticipant.usertalent.user.profile.thumb &&
                                                <img src={"https://kurdishtalent.com"+item.courseparticipant.usertalent.user.profile.thumb} className="document-user-img" alt={ item.courseparticipant.usertalent.user.profile.first_name} />
                                            }

                                            <span className="circle-artist-name ">
                                                { item.courseparticipant.usertalent.user.profile.first_name}
                                            </span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>

                    ))}
                </div>
                <div className="clear"></div>

    </>
  )
}

export default CourseParticipantsChampionCircles