import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import ShowVideoModal from '../dialogs/ShowVideoModal';
import SocialLogin from '../forms/user/SocialLogin';
import EmailLoginModal from './EmailLoginModal';


export default function LoginModal({ isOpen,setOpen, setLoggedIn,onClose }) {

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [emailLoginModalIsOpen, setEmailLoginModalIsOpen] = useState(false);

    useEffect(() => {
        setModalIsOpen(isOpen)
    }, [])

    const closeModal = async () =>  {
        setModalIsOpen(false)
        // setTimeout(function(){}, 5050);
        setOpen && setOpen(false)
        onClose && onClose()
    }


    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal modal-social-login "
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content"> 
                    
                    <div className="modal-body p-6">
                        <div className="btn btn-close" onClick={closeModal}><i className="fa fa-times"></i></div>
                        <SocialLogin goToEmailRegister={()=>{ setEmailLoginModalIsOpen(true) }} />
                    </div>
                    
                </div>
            </Modal>
            <EmailLoginModal key={emailLoginModalIsOpen} setOpen={setEmailLoginModalIsOpen} setLoggedIn={()=>{}} isOpen={emailLoginModalIsOpen} />
            
        </>
    )
}
