import React, { useContext } from 'react'
import { UserHttpService } from '../../../services/user';
// import { signInWithFacebook, signInWithGoogle, signInWithTwitter } from '../../../services/third-party/firebase';
import parse from 'html-react-parser'

function SocialLogin({ setLoading, setError, ...props }) {



  const _signInWithSocial = async (provider = "google") => {

    
    setLoading(true);
    setError("")
    var response;
    if (provider === "facebook") {
      // response = await signInWithFacebook()
    }
    if (provider === "google") {
      // response = await signInWithGoogle()
    }

    if (provider === "twitter") {
      // response = await signInWithTwitter()
    }

    console.log(response)

    if (response?.uid) {
      UserHttpService.registerSocial(response).then((user) => {
        setLoading(false);
        if (user.role) { // user saved successfully
          // setDone(true)

          // HttpService.setToken(user.access)
          // HttpService.setUser(user)

          // setAuthenticate(user)
          if (props.onLoggedIn) {
            props.onLoggedIn(user)
          }
          else {
            if (response.role == 'admin') {
              window.location.href = "/cms"
            }
            else {
              window.location.href = "/"
            }
          }
        }

      })
    }
    else {
      setLoading(false);
      setError("an error occurred while signing in")
    }
  }



  return (
    <div className="d-flex flex-column align-items-center social-images lan-kfont lan-rtl">
      <p className="text-center">{"Please sign up or sign in to participate"}</p>
      <div className="d-flex justify-content-center py-3">
        <div className="mr-3" onClick={() => { _signInWithSocial("facebook") }} title="Sign in with facebook"><img src="assets/images/social/colored/facebook.png" alt="" /></div>
        <div className="mr-3" onClick={() => { _signInWithSocial("google") }} title="Sign in with google"><img src="assets/images/social/colored/google.png" alt="" /></div>
        <div className="mr-3" onClick={() => { _signInWithSocial("twitter") }} title="Sign in with twitter"><img src="assets/images/social/colored/twitter.png" alt="" /></div>
        {props?.goToEmailRegister && (
          <div className="" title="Sign in with email and password" onClick={() => { props.goToEmailRegister() }}><img src="assets/images/social/colored/email.png" alt="" /></div>
        )}
      </div>
    </div>
  )
}

export default SocialLogin