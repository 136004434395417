import React, {  useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import People from "./pages/People";
import Footer from "./components/base/Footer";
import Header from "./components/base/Header";
import Home from "./pages/shared/Home";
import Artist from "./pages/sections/participant/Artist";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignOut from "./pages/SignOut";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import Validate from "./pages/Validate";
import Referee from "./pages/Referee";
import Forgot from "./pages/user/Forgot";
import Reset from "./pages/Reset";
import Videos from "./pages/Videos";
import Post from "./pages/shared/post/Post";
import Posts from "./pages/shared/post/Posts";
import About from "./pages/shared/About";

import Message from "./pages/Message";
import MessageDetails from "./pages/MessageDetails";
import Faqs from "./pages/Faqs";
import Slug from "./pages/Slug";
import CourseHistory from "./pages/sections/contest/CourseHistory";
import Poll from "./pages/sections/contest/Poll";
import Squares from "./pages/Squares";
import Dashboard from "./pages/sections/participant/Dashboard";
import Competition from "./pages/sections/contest/competition";

function MainRoutes({translations}) {
  

  return (
      <Router  basename="/"> 
        <Header translations={translations} />
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={SignOut} path="/signout" exact />
          <Route component={Forgot} path="/forgot" exact />
          <Route component={Reset} path="/reset" exact />
          <Route component={Validate} path="/validate" exact />
          <Route component={Videos} path="/talent" exact />
          <Route component={Videos} path="/talent/:id" />
          <Route component={Posts} path="/blog/:id" />
          <Route component={Posts} path="/blog" />
          <Route component={Post} path="/post/:id" />
          <Route component={About} path="/about" />
          <Route component={Faqs} path="/faq" />
          <Route component={CourseHistory} path="/history/:id" />
          <Route component={People} path="/people/:id" />
          <Route component={Poll} path="/poll/:id" />
          <Route component={Poll} path="/vote/:id" />
          <Route component={Poll} path="/vote" />
          <Route component={Squares} path="/squares" />
          

          <PublicRoute component={SignIn} path="/signin" exact />
          <PrivateRoute component={Artist} path="/mytalents" exact />
          <PrivateRoute component={Dashboard} path="/dashboard" exact />
          <PrivateRoute component={Referee} path="/referee" exact />
          <PrivateRoute component={Profile} path="/profile" exact />
          <PrivateRoute component={Competition} path="/contests" exact />
          {/* <PrivateRoute component={Talent} path="/mytalents" exact /> */}
          <PrivateRoute component={Message} path="/support" exact />
          <PrivateRoute component={MessageDetails} path="/support/:id" exact />
          <Route component={Slug} path="/:slug" />
        </Switch>
        <Footer translations={translations} />
      </Router>
  );
}
export default MainRoutes;
