import React from 'react'

function StepIndicator({index=0}) {
  return (
    <div className='step-indicator'>
        <div className={`step-indicator-item ${index>=0 ? 'indicator-active' : ""}`}>
            <i className="fa fa-check"></i>
        </div>
        <div className={`step-indicator-spacer ${(index>0) ? 'spacer-active' : ""} `}>
            <div className="spacer-inside"></div>
        </div>
        <div className={`step-indicator-item ${(index>0) ? 'indicator-active' : ""}`}>
            <i className="fa fa-check"></i>
        </div>
        <div className={`step-indicator-spacer ${(index>1) ? 'spacer-active' : ""} `}>
            <div className="spacer-inside"></div>
        </div>
        <div className={`step-indicator-item ${(index>1) ? 'indicator-active' : ""}`}>
            <i className="fa fa-check"></i>
        </div>
        <div className={`step-indicator-spacer ${(index>2) ? 'spacer-active' : ""} `}>
            <div className="spacer-inside"></div>
        </div>
        <div className={`step-indicator-item ${(index>2) ? 'indicator-active' : ""}`}>
            <i className="fa fa-check"></i>
        </div>
    </div>
  )
}

export default StepIndicator