import React, { useEffect, useRef, useState } from 'react'
import { logout } from '../../utils';
import { loadProfile, uploadProfileImage } from '../../utils/Network';


export default function ProfileInformation(props) {

    const [profile, setProfile] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileImageLoading, setProfileImageLoading] = useState(false);

    const imageUploadRef = useRef(null);
    useEffect(() => {
        setLoading(true);

        loadProfile().then((profile) => {
            setProfile(profile);
            setLoading(false);
            if (profile) {
                setRole(profile.role);
            } else {
                logout();
                window.location.href = "signin";
            }
        });
    }, []);

    const handleChanges = (e) => {
        var file = e.target.files[0];

        if (file) {
            //setFile(file);
            setProfileImageLoading(true);
            uploadProfileImage(profile.id, file).then((data) => {
                if (data.image) {
                    setProfile(data);
                }
                setProfileImageLoading(false);
            });
        }
    };


    return (
        <div className='profile-information-container'>
            <div className="profile-image-aside-container bg-color-primary">
                <img
                    alt={profile && profile.first_name}
                    src={
                        profile && profile.image
                            ? profile.image
                            : "assets/images/user.png"
                    }
                />
                <span className="profile-image-button profile-information-image-button" onClick={()=>{ imageUploadRef.current.click() }}>
                    <i className="fas fa-camera text-light"></i>
                </span>
                <input
                    type="file"
                    ref={imageUploadRef}
                    id="file"
                    name="profileimage"
                    onChange={handleChanges}
                    className="profile-image-input"
                />
                {profileImageLoading && (
                    <>
                        <div
                            className="loading-overlay-showing border-radius-50 opacity-8"
                            data-loading-overlay
                            data-plugin-options="{'hideDelay': 500, 'effect': 'floatRings'}"
                        >
                            <div className="loading-overlay border-radius-50">
                                <div className="bounce-loader">
                                    <div className="cssload-float-rings-loader">
                                        <div className="cssload-float-rings-inner cssload-one"></div>
                                        <div className="cssload-float-rings-inner cssload-two"></div>
                                        <div className="cssload-float-rings-inner cssload-three"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <h1 className="font-weight-bold text-light mb-20">
                {profile && profile.first_name + " "}
                {profile && profile.last_name}
            </h1>
            <div className="mb-20">
                <ul className="social-icons">
                    {profile && profile.website ? (
                        <>
                            <li className="social-icons-website">
                                <a
                                    href={profile.website}
                                    rel="noreferrer"
                                    target="_blank"
                                    title="Website"
                                >
                                    <i className="fa fa-home"></i>
                                </a>
                            </li>
                        </>
                    ) : null}
                    {profile && profile.facebook ? (
                        <>
                            <li className="social-icons-facebook">
                                <a
                                    href={profile.facebook}
                                    rel="noreferrer"

                                    target="_blank"
                                    title="Facebook"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                        </>
                    ) : null}
                    {profile && profile.instagram ? (
                        <>
                            <li className="social-icons-instagram">
                                <a
                                    href={profile.instagram}
                                    rel="noreferrer"

                                    target="_blank"
                                    title="Instagram"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                        </>
                    ) : null}
                    {profile && profile.youtube ? (
                        <>
                            <li className="social-icons-youtube">
                                <a
                                    href={profile.youtube}
                                    rel="noreferrer"

                                    target="_blank"
                                    title="Youtube"
                                >
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </li>
                        </>
                    ) : null}
                    {profile && profile.snapchat ? (
                        <>
                            <li className="social-icons-snapchat">
                                <a
                                    href={profile.snapchat}
                                    rel="noreferrer"

                                    target="_blank"
                                    title="Snapchat"
                                >
                                    <i className="fab fa-snapchat"></i>
                                </a>
                            </li>
                        </>
                    ) : null}
                    {profile && profile.spotify ? (
                        <>
                            <li className="social-icons-spotify">
                                <a
                                    href={profile.spotify}
                                    rel="noreferrer"
                                    target="_blank"
                                    title="Spotify"
                                >
                                    <i className="fab fa-spotify"></i>
                                </a>
                            </li>
                        </>
                    ) : null}
                </ul>
            </div>
        </div>
    )
}
