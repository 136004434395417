import React, { useEffect, useState } from "react";
import {
  getCurrentLanguageId,
  getDay,
  getDefaultValue,
  getMonth,
} from "../../utils";
import parse from "html-react-parser";

import { loadPosts, loadTestimonials } from "../../utils/Network";

export default function LatestPosts({ translations }) {
  const [posts, setPosts] = useState(null);
  const [testimonials, setTestimonials] = useState(null);

  const language = getCurrentLanguageId();

  useEffect(() => {
    loadPosts(null, 6).then((posts) => {
      setPosts(posts);
    });

    loadTestimonials(language).then((testimonials) => {
      setTestimonials(testimonials);
    });
  }, []);

  const translate = (str) => {
    if (!translations) return str;
    var translate = translations.find(x=>x.key === str );

    if (translate) return getDefaultValue(translate);
    else return "#" + str;
  };

  return (
    <section
      className="section hide section-custom-map appear-animation appear-animation-visible"
      data-appear-animation="fadeInUpShorter"
    >
      <section className="section section-default section-footer">
        <div className="container">
          <div
            className="row mt-5 appear-animation appear-animation-visible"
            data-appear-animation="fadeInUpShorter"
          >
            <div className="col-lg-6">
              <div className="recent-posts mb-5">
                <h2 className="font-weight-normal text-6 mb-4">
                  <strong className="font-weight-extra-bold">
                    {translate("Latest Posts")}
                  </strong>{" "}
                </h2>
                <div
                  className="owl-carousel owl-theme dots-title mb-0"
                  data-plugin-options="{'items': 1, 'autoHeight': true}"
                >
                  {posts &&
                    posts.map((post) => (
                      <div key={post.id} className="row">
                        <div className="col-lg-12 mb-4 mb-lg-0">
                          <article>
                            <div className="row lan-rtl">
                              <div className="col-auto pr-0">
                                <div className="date">
                                  <span className="day font-weight-extra-bold">
                                    {getDay(post.updated_at)}
                                  </span>
                                  <span className="month text-1">
                                    {getMonth(post.updated_at)}
                                  </span>
                                </div>
                              </div>
                              <div className="col pl-1">
                                <h4 className="text-primary text-4">
                                  <a
                                    className="d-block"
                                    href={`post/${post.id}`}
                                  >
                                    {post.title}
                                  </a>
                                </h4>
                                <p className="pr-4 mb-0 lan-rtl text-justify lan-padding-r-0">
                                  {post.short_description}
                                </p>
                                <a
                                  href={`post/${post.id}`}
                                  className="read-more text-color-dark font-weight-semibold text-2"
                                >
                                  read more{" "}
                                  <i className="fas fa-angle-right position-relative top-1 ml-1"></i>
                                </a>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="font-weight-normal text-6 mb-4">
                <strong className="font-weight-extra-bold">
                  {translate("What People Say")}
                </strong>{" "}
              </h2>
              <div className="row">
                <div
                  className="owl-carousel owl-theme dots-title dots-title-pos-2 mb-0"
                  data-plugin-options="{'items': 1, 'autoHeight': true}"
                >
                  {testimonials?.map((item) => (
                    <div key={item.id} className="col">
                      <div className="testimonial testimonial-primary">
                        <blockquote>
                          <p className="mb-0 kfont lan-text-right">{parse(item.description)}</p>
                        </blockquote>
                        <div className="testimonial-arrow-down"></div>
                        <div className="testimonial-author">
                          <div className="testimonial-author-thumbnail">
                            <img
                              src="img/clients/client-1.jpg"
                              className="rounded-circle"
                              alt=""
                            />
                          </div>
                          <p>
                            <strong> {item.name}</strong>
                            <span> {item.title}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
