
import {HttpService} from '.'
import { getCurrentLanguageId, getPublicVoteCookie } from '..';

export class ApiHttpService{

    static async loadCourseHistories(){
        var service = new HttpService();
        var response = await service.get(`api/coursehistory`,null);
        return response;
    }

    static async loadCourseHistory(id){
        var service = new HttpService();
        var response = await service.get(`api/coursehistory/${id}`,null);
        return response;
    }

   

    static async loadCourseHistoryTalents(coursehistory,topten=0){
        var service = new HttpService();
        var response = await service.get(`api/coursehistorytalent/?coursehistory=${coursehistory}&topten=${topten}`,null);
        return response;
    }

    static async loadCourseHistoryReferees(coursehistory,topten=0){
        var service = new HttpService();
        var response = await service.get(`api/coursehistoryreferee/?coursehistory=${coursehistory}&topten=${topten}`,null);
        return response;
    }

    /////////////////////////////////////////////////////////////////////

    static async loadPublicProfile(id){
        var service = new HttpService();
        var response = await service.get(`auth/profile/public/${id}`,null);
        return response;
    }

    /////////////////////////////////////////////////////////////////////

     static async findBySlug(slug){

        let language_id = getCurrentLanguageId();

        var service = new HttpService();
        var response = await service.get(`api/slug/?slug=${slug}&language=${language_id}`,null);
        return response;
    }
     /////////////////////////////////////////////////////////////////////

     static async loadPolls(){
        var service = new HttpService();
        var response = await service.get(`api/poll`,null);
        return response;
    }

    static async loadPoll(id){
        var service = new HttpService();
        var response = await service.get(`api/poll/${id}`,null);
        return response;
    }

    static async loadPollItems(poll){
        var service = new HttpService();
        var response = await service.get(`api/pollitem/?poll=${poll}`,null);
        return response;
    }

    static async vote(body){
        var service = new HttpService();
        var response = await service.post(`api/vote/`,body);
        return response;
    }

    static async loadVotes(poll){
        var cookie = getPublicVoteCookie()
        var service = new HttpService();
        var response = await service.get(`api/vote/?poll=${poll}&cookie=${cookie}`,null);
        return response;
    }
}