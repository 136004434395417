import React from 'react'
import { useStateValue } from '../../StateProvider';
import { getDefaultValue } from '../../utils';
import AddUserTalentModal from '../dialogs/AddUserTalentModal'

function WidgetAddDocument() {
    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find((x) => x.key == str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };


    return (
        <>
            <div className="kt-widget" data-toggle="modal" data-target="#addUserTalentModal">
                <i className="fa fa-video"></i>
                {translate("Add Video")}
            </div>
            <AddUserTalentModal />
        </>

    )
}

export default WidgetAddDocument