import React, { useEffect, useState } from 'react'
import { getDay, getDefaultValue, getMonth } from '../../../utils';
import { loadPost } from '../../../utils/Network';
import parse from 'html-react-parser'
import { useStateValue } from '../../../StateProvider';
import TalentName from '../../../components/talent/TalentName';


export default function PostContent({ id }) {

    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState(null);

    const current_page_url = window.location.href



    useEffect(() => {
        setLoading(true)
        document.title = 'KurdishTalent | Blog '

     

        if (id) {
            loadPost(id).then(post => {
                if (post) {
                    setPost(post)
                    document.title = 'KurdishTalent | ' + post.title
                }

                setLoading(false)
            })
        }



    }, [])

    const [{ translations }] = useStateValue();
    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x=>x.key == str );
    
        if (translate) return getDefaultValue(translate);
        else return "#" + str;
      };

      
    return (
        <div id="main" role="main" className="main">

            <section className="page-header pb-0 section-with-shape-divider page-header-modern bg-color-dark-scale-1 page-header-lg mb-0 pb-4  page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7 ">


            </section>

            <div className="container pb-2">
                {loading &&
                    <>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </>
                }

                {!loading && post &&
                    <>
                        <div className="col">
                            <div className="blog-posts single-post">

                                <article className="post post-large blog-single-post border-0 m-0 p-0">
                                    <div className="post-date ml-0">
                                        <span className="day">{getDay(post.created_at)}</span>
                                        <span className="month">{getMonth(post.created_at)}</span>
                                    </div>

                                    <div className="post-content ml-0">

                                        <h2 className="font-weight-semi-bold">{post.title}</h2>

                                        <div className="post-meta">
                                        <span><i className="far fa-user"></i> By <a href="#KurdishTalent" className="kfont">{translate('Kurdish Talent')}</a> </span>
                                        <span><i className="far fa-folder"></i> <a href={`blog/${post && post.talent}`} className="kfont"><TalentName id={post.talent} /></a> </span>
                                        </div>



                                    </div>


                                    <div className="description text-justify">

                                        <div className="lan-rtl lan-text-right" >
                                            <div className="animate-img-container ml-4 mt-2 pull-right lan-float-left lan-mr-10 lan-ml-0">
                                            <img src={post.gallery_image ? post.gallery_image.image : "assets/images/default.png"} className="img-fluid " alt="" />

                                            </div>
                                            {post.short_description &&
                                                <div className="short_description">{post.short_description}</div>
                                            }

                                            {parse(post.description)}
                                    </div>
                                    <div className="share">
                                        <h4 className="mb-3">{translate('Share this post')}</h4>
                                        <ul className="social-icons text-left social-icons-medium social-icons-dark-2 lan-text-right">
                                            <li className="social-icons-facebook"><a href={`https://www.facebook.com/sharer/sharer.php?u=${current_page_url}`} target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="social-icons-twitter"><a href={`http://twitter.com/share?text=${post && post.title}&url=${current_page_url}&hashtags=kurdishtalent`} target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                            <li className="social-icons-linkedin"><a href={`https://www.linkedin.com/sharing/share-offsite/?url=${current_page_url}`} target="_blank" title="Share on Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                    

                                    </div>




                                </article>

                            </div>
                        </div>
                    </>
                }


            </div>

        </div>
    )

}
