import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useRef, useState } from 'react'
import { useStateValue } from '../../../StateProvider';
import { getDefaultValue, getExtension, Mg } from '../../../utils';
import uuid from "react-uuid";
import { app } from '../../../services/third-party/firebase';
import { ContestHttpService } from '../../../utils/http/contest_http_service';
import LoadingOverlay from '../../shared/LoadingOverlay';

function UploadDocumentForm({ talentId, max_file_size = 20 * 1024 * 1024, step = 0, onSuccess }) {

    const [userDocument, setUserDocument] = useState(null);
    const [userDocumentUrl, setUserDocumentUrl] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [upload_modal_error, setUploadModalError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [upload_error, setUploadError] = useState(false);

    const [{ translations }] = useStateValue();
    const upload_enabled = true;
    var allowed_extensions = "mp4,mpeg4,mov";
    const inputFileRef = useRef(null);

    const handleTextChange = (e) => {
        switch (e.target.name) {
            case "document_url":
                setUserDocumentUrl(e.target.value);
                break;

            default:
                break;
        }
    };

    const handleFileChange = (e) => {
        var selectedFiles = e.target.files;
        var userdocument_id = e.target.getAttribute("userdocument_id");

        if (selectedFiles.length) {
            //console.log(selectedFiles[0].name)
            let ext = getExtension(selectedFiles[0].name);
            let file_size = selectedFiles[0].size;
            if (file_size >= max_file_size + 1) {
                setUploadError(
                    "it seems that your file is too big. Maximum allowed file size is " +
                    Mg(max_file_size) +
                    " MB"
                );
            } else {
                console.log("Uploading...");
                if (allowed_extensions && allowed_extensions.split(",").includes(ext)) {
                    //if selected file is a video, Upload it to firebase storage.
                    if (allowed_extensions.split(",").includes("mp4")) {
                        uploadToFirebase(selectedFiles[0]).then((data) => { });
                    } else {
                        // if selected file is not a video, upload it to our server.
                        if (userdocument_id) {
                            setLoading(true);
                            //   updateUserDocument(
                            //     userdocument_id,
                            //     document_id,
                            //     selectedFiles[0]
                            //   ).then((data) => {
                            //     setUserDocument(data);
                            //     setLoading(false);
                            //   });
                        } else {
                            setLoading(true);
                            //   uploadUsertalentDocument(
                            //     usertalent,
                            //     document_id,
                            //     selectedFiles[0],
                            //     "",
                            //     null,
                            //     talent_id
                            //   ).then((data) => {
                            //     if (data.Error) alert(data.Error);
                            //     else setUserDocument(data);
                            //     setLoading(false);
                            //   });
                        }
                    }
                } else {
                    setUploadError(
                        "This document type is not allowed. allowed types :" +
                        allowed_extensions
                    );
                }
            }
        }
    };

    async function uploadToFirebase(file) {
        // setUserDocument(null)
        setLoading(true);
        var uid = uuid();
        var file_name = uid + file.name.substr(file.name.length - 5);
        const storage = getStorage(app);
        const storageRef = ref(storage, `videos/${file_name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        await uploadTask.on(
            "state_changed",
            (snapShot) => {
                var progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                setProgress(progress);
                console.log(progress + " %");
            },
            (err) => {
                //catches the errors
                console.log(err);
            },
            () => {
                setLoading(false);
                console.log("File uploaded successfully. saving changes ...");
                getDownloadURL(ref(storage, `videos/${file_name}`))
                    .then((url) => {
                        console.log(url);
                        saveUserDocumentUrl(url);
                    })
                    .catch((error) => {
                        // Handle any errors
                    });
            }
        );
    }

    const saveUserDocumentUrl = (url) => {
        if (!url) {
            setUploadModalError("Please Enter the url");
            return;
        }

        setUploadModalError(false);
        setLoading(true);
        setUploading(true);

        var body = {
            "talent": talentId,
            "url": url
        }

        ContestHttpService.saveUsertalentDocument(body).then((response) => {
            setLoading(false);
            setUploading(false);
            if (response.Error) setUploadModalError(response.Error);
            else {
                setUserDocument(response);
                console.log(response)
                response.courses = "[]"
                if (onSuccess) {
                    onSuccess(response)
                }
            }
        })
    };


    const translate = (str) => {
        if (!translations) return str;
        var translate = translations.find(x => x.key === str);

        if (translate) return getDefaultValue(translate);
        else return "#" + str;
    };

    const selectFile = () => {
        inputFileRef.current.click();
        // setUploadModalOpen(false);
    };

    return (
        <div className="row document-upload-container">
            {upload_enabled && (
                <div className="col-md-12 text-center mb-3 mt-3  ">
                    {allowed_extensions.split(",").includes("mp4") ? (
                        <h2>{translate("Upload your video")}</h2>
                    ) : (
                        <>
                            <p>{translate("Upload your document here")}</p>
                        </>
                    )}

                    <input
                        type="file"
                        ref={inputFileRef}
                        name="upload_file"
                        className="hide"
                        onChange={handleFileChange}
                    />

                    <button
                        className="btn btn-primary btn-upload-file"
                        onClick={() => { selectFile() }}
                    >
                        <i className="fa fa-upload"></i> &nbsp;
                        {allowed_extensions.split(",").includes("mp4") ? (
                            <>{translate("Select from your device")}</>
                        ) : (
                            <>{translate("Upload")}</>
                        )}
                    </button>
                    <br />
                    <small>
                        {"Max Size:" +
                            " " +
                            Mg(max_file_size) +
                            " Mb  | " +
                            "Allowed formats:" +
                            " " +
                            allowed_extensions.replaceAll(",", ", ")}
                    </small>
                </div>
            )}

            {allowed_extensions.split(",").includes("mp4") && (
                <div className='row col-md-12 flex justify-content-center'>
                    <div className="full-width col-md-12 orcontainer">
                        <div className="width-40p  ">
                            <hr className="full-width" />
                        </div>
                        <div className="width-20p kfont text-center mb-2">
                            {translate("Or")}
                        </div>
                        <div className="width-40p ">
                            <hr className="full-width" />
                        </div>
                    </div>
                    <div className="col-md-8 text-center ">
                        <div className="text-color-primary mb-5">

                            <h2>{translate("From Youtube")}</h2>

                            <div className="sendurlcontainer">
                                <div className="padding-5">
                                    <i className="fab fa-youtube font-34"></i>
                                </div>
                                <div className="padding-5 mb-2 big">
                                    <input
                                        type="text"
                                        name="document_url"
                                        onChange={handleTextChange}
                                        className="form-control ltr mb-1 border-color-gray3 "
                                        placeholder={translate("Paste video url here")}
                                    // defaultValue={(userDocument && 
                                    //   !userDocument.url.includes("firebasestorage") ) &&  userDocument.url }
                                    />
                                </div>
                                <div className="padding-5">
                                    <button
                                        disabled={uploading}
                                        className="btn btn-primary "
                                        onClick={() => { saveUserDocumentUrl(userDocumentUrl) }}
                                    >
                                        {translate("Save")}
                                    </button>
                                </div>
                            </div>
                            {upload_modal_error && (
                                <>
                                    <div className="alert alert-danger">
                                        {upload_modal_error}
                                    </div>
                                </>
                            )}

                        </div>
                    </div>

                </div>

            )}
            {uploading && (
                <LoadingOverlay loading={uploading} />
            )}
        </div>
    )
}

export default UploadDocumentForm