import { HttpService } from "../../services/http";
import { ApiBase } from "../Network";

export class ContestHttpService {
    
static async saveUsertalentDocument(body) {
    // let formData = new FormData();
    // formData.append("url", body.url);
    // formData.append("talent", body.talent);
    // if (body.file) formData.append("file", body.file, body.file.name);

    var service = new HttpService();
    var response = await service.post(`api/usertalent/userdocument/`,body);
  
    return response;
  }

  static async loadContests(query) {

    var service = new HttpService();
    var response = await service.get(`api/course/${query}`);
  
    return response;
  }

  static async loadCourseParticipant(query) {
    var service = new HttpService();
    var response = await service.get(`api/courseparticipant/${query}`);
  
    return response;
  }

  static async participate(body) {
    var service = new HttpService();
    var response = await service.post(`api/courseparticipant/participate/`,body);
  
    return response;
  }

}