import React from 'react'
import  './Signin.css';

function Squares() {
  return (
    <div>
        <div className="squares">
            {Array(550).fill().map((x,i)=>(
                <div className="square">
                    <img src="assets/images/img-2.jpeg" alt="" />
                </div>
            ))}
        </div>
    </div>
  )
}

export default Squares

