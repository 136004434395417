import { loadCourse } from "../utils/Network";
import parse from "html-react-parser";
import { useState } from "react";

const TOKEN_KEY = "cjtokennow";

export const logout = () => {
  removeUserSession();
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY) || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("user");
};

export const getDefaultName = (item_names,field="name") => {
  let language_id = getCurrentLanguageId();

  if (item_names) {
    var default_name = item_names.find((x) => x.language == language_id);
    if (default_name){

      return default_name[field];
      switch (field){
        case "referee_title":
          return default_name?.referee_title;
        case "referee_description":
          return default_name?.referee_description;
        case "name":
          return default_name.name;
        default: 
          return default_name.name;
      }
         
    }
    else return item_names[0].name;
  }
  return "";
};



export const getDefaultValue = (translate) => {
  let language_id = getCurrentLanguageId();

  if (translate.values) {
    var default_value = translate.values.find((x) => x.language == language_id);
    if (default_value) return default_value.value;
    else return "#"+translate.key;
  }
  return "#"+translate.key;
};
export const getName = (item_names) => {
  let language_id = getCurrentLanguageId();

  if (item_names) {
    var default_name = item_names.find((x) => x.language == language_id);
    if (default_name) return default_name;
    else return item_names[0];
  }
  return "";
};

export const getDefaultDescription = (item_names) => {
  let language_id = getCurrentLanguageId();

  if (item_names) {
    var default_name = item_names.find((x) => x.language == language_id);
    if (default_name)
      return parse(default_name.description ? default_name.description : "");
    else
      return parse(item_names[0].description ? item_names[0].description : "");
  }
  return "";
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem("user", JSON.stringify(user));
};
export const changeTheme = (e) => {
  e.preventDefault();
  var theme = "dark";
  if (localStorage.getItem("theme")) {
    theme = localStorage.getItem("theme");
  }
  if (theme === "dark") {
    theme = "light";
    localStorage.setItem("theme", theme);
  } else {
    theme = "dark";
    localStorage.setItem("theme", theme);
  }
  if (theme === "light")
    document.getElementsByTagName("html")[0].classList.remove("dark");
  else document.getElementsByTagName("html")[0].classList.add("dark");
  return theme;
};
export const getTheme = () => {
  var theme = "dark";
  if (localStorage.getItem("theme")) {
    theme = localStorage.getItem("theme");
  }

  if (theme === "dark") return "Light Theme";
  else return "Dark Theme";
};
export const getSelectedTheme = () => {
  var theme = "dark";
  if (localStorage.getItem("theme")) {
    theme = localStorage.getItem("theme");
  }

  if (theme === "dark") return "dark";
  else return "light";
};
export const setTheme = () => {
  var theme = "dark";
  if (localStorage.getItem("theme")) {
    theme = localStorage.getItem("theme");
  }
  if (theme === "light")
    document.getElementsByTagName("html")[0].classList.remove("dark");
  else document.getElementsByTagName("html")[0].classList.add("dark");
};

export const isImage = (path) => {
  if (!path) return false;

  var fileType = path.split(".").pop();
  var validImageTypes = [
    "gif",
    "GIF",
    "jpeg",
    "JPEG",
    "jpg",
    "JPG",
    "png",
    "PNG",
  ];
  if (validImageTypes.includes(fileType)) {
    return true;
  } else return false;
};
export const isVideo = (path) => {
  var fileType = path.split(".").pop();
  var validImageTypes = ["mp4", "mov", "wmv", "AMI", "AVI"];
  if (validImageTypes.includes(fileType)) {
    return true;
  } else return false;
};

export const Mg = (value) => {
  if (parseFloat(value) > 0)
    return Math.round(parseFloat(parseFloat(value) / (1024 * 1024)), 3);
  else return value;
};

export const isDocument = (path) => {
  if(!path) return false;
  var fileType = path.split(".").pop();
  var validImageTypes = ["pdf", "doc", "docx", "txt"];
  if (validImageTypes.includes(fileType)) {
    return true;
  } else return false;
};
export const isYoutube = (path) => {
  if(!path) return false;
  if (path.includes("youtube.com") || path.includes("youtu.be")) return true
  return false

};

export const getFileName = function (str) {
  return str.replace(/^.*(\\|\/|\:)/, "");
};



export const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
      ? match[2]
      : null;
}

export const toTime = function (time) {
  var minutes = Math.floor(time / 60);
  var seconds = time - minutes * 60;
  if (minutes + seconds > 0) return minutes + ":" + seconds;
  else return "";
};

export const getCourseNames = function (str_courses) {
  if (!str_courses) return "";
  return "";
  var ids = str_courses.split(",");
  if (ids && ids.length > 0) {
    ids.map((item, index) => {
      loadCourse(item).then((course) => {
        return (
          "This document used in a competition : " +
          getDefaultName(course.names)
        );
      });
    });
  }
  return "";
};

export const BuildTalents = (talents, active_only) => {
  if (talents && talents.code) {
    window.location.href = "signout";
  }

  if (talents) {
    var parents = [];

    if (active_only) parents = talents.filter((x) => !x.parent && x.status);
    else parents = talents.filter((x) => !x.parent);

    parents.forEach((parent) => {
      parent.childs = getChilds(talents, parent.id, active_only);
    });

    return parents;
  }
};

export const getDate = (dateString) => {
  var dateObject = new Date(dateString);
  return dateObject.toDateString();
};
export const getYearMonth = (dateString) => {
  if (!dateString) return dateString;
  var dateObject = new Date(dateString);
  const month = dateObject.toLocaleString("default", { month: "short" });
  return dateObject.getFullYear() + " " + month;
};
export const getYear = (dateString) => {
  var dateObject = new Date(dateString);
  return dateObject.getFullYear();
};
export const getDay = (dateString) => {
  var dateObject = new Date(dateString);
  return dateObject.getDay();
};

export const getMonth = (dateString) => {
  var dateObject = new Date(dateString);
  const month = dateObject.toLocaleString("default", { month: "short" });

  return month;
};
export const getChilds = (all_talents, talent_id, active_only) => {
  var childs = [];
  if (active_only)
    childs = all_talents.filter((x) => x.parent === talent_id && x.status);
  else childs = all_talents.filter((x) => x.parent === talent_id);

  childs.forEach((item) => {
    item.childs = getChilds(all_talents, item.id, active_only);
  });
  return childs;
};
export function sortCities(array) {
  return array.sort(function (a, b) {
    //console.log(a["names"][0])

    var x = a["names"][0]["name"];
    var y = b["names"][0]["name"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}
export function getExtension(file_name) {
  if (file_name) return file_name.split(".").pop().toLowerCase();
}

export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    //console.log(a["names"][0])
    var x = a["names"][0]["name"];
    var y = b["names"][0]["name"];

    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function days_between(date1, date2) {
  //console.log(date1,date2)
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}

export const setCurrentLanguage = (language,change_direction=true) => {
  
  localStorage.setItem("language", language);
  //document.cookie = `language=${language}`

  if (change_direction)
    if (language === "so")
      document.getElementsByTagName("html")[0].classList.add("rtl");
    else document.getElementsByTagName("html")[0].classList.remove("rtl");
};
export const getCurrentLanguageId = () => {
  let lang = localStorage.getItem("language");
  //let lang = readCookie("language")
  if (!lang) return 3;

  if (lang === "en") return 3;
  if (lang === "so") return 2;
  if (lang === "ku") return 1;

  return lang;
};


export const changeLanguage = (e, lang) => {
  e.preventDefault();
  setCurrentLanguage(lang,false);
  window.location.href =
    window.location.origin + window.location.pathname + `?lang=${lang}`;
};

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  //var ca = localStorage.getItem("language")?.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const getCurrentLanguage = () => {
  let lang = localStorage.getItem("language");

  //let lang = readCookie("language");
  if (!lang) lang = "en";
  if (lang === "so")
    document.getElementsByTagName("html")[0].classList.add("rtl");
  else document.getElementsByTagName("html")[0].classList.remove("rtl");
  return lang;
};

export async function readLanguageFile() {
  const lang = getCurrentLanguage();
  var file = "";
  if (lang === "so") {
    file = `assets/so.json`;
  } else if (lang === "ku") {
    file = `assets/ku.json`;
  }

  

  if (file) {
    const response = await fetch(file, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const data = await response.json();

    return data;
  } else return null;
}
export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export const setPublicVoteCookie=()=>{
  let random = (Math.random() + 1).toString(36).substring(7);
  localStorage.setItem('pvc', random);
  return random;
}

export const getPublicVoteCookie=()=>{
  return localStorage.getItem('pvc');
}

export const translate = (translations,str) => {
  if (!translations) return str;
  var translate = translations.find(x=>x.key == str );

  if (translate) return getDefaultValue(translate);
  else return "#" + str;
};

export const userAvatar = (profile) => {
  let res = "/assets/images/user.png";
  if(profile){
    if(profile?.avatar){
      res = profile?.avatar
      if(!profile.avatar.includes("http")){
        console.log("https://kurdishtalent.com"+profile?.avatar)
        res = "https://kurdishtalent.com"+profile?.avatar
      }
    }
  }
  return res;
};

export const userImage = (profile) => {
  let res = "/assets/images/user.png";
  if(profile){
    if(profile?.image){
      res = profile?.image
      if(!profile.image.includes("http")){
        console.log("https://kurdishtalent.com"+profile?.image)
        res = "https://kurdishtalent.com"+profile?.image
      }
    }
  }
  return res;
};

