import React, { useEffect, useState } from 'react'
import { getCourseNames, getYoutubeId, isDocument, isImage, isYoutube, toTime, translate } from '../../utils';
import { deleteUserTalentDocument } from '../../utils/Network';
import ModalDeleteUserDocument from '../dialogs/ModalDeleteUserDocument';
import parse from 'html-react-parser'
import LoadingOverlay from '../shared/LoadingOverlay';
import { useStateValue } from '../../StateProvider';


function UserDocumentPreview({ showVideoMessage = false, userDocument, setUserDocument }) {
    const [duration, setDuration] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteDocumentModalIsOpen, setDeleteDocumentModalIsOpen] = useState(false);
    const [progress, setProgress] = useState(false);
    const [{ translations }] = useStateValue();

    var editing = false;
    var deleting = false;

    useEffect(() => {

    }, []);


    const handleVideoMounted = (element) => {
        if (element !== null && element) {
            //element.currentTime = 2;
            if (!isNaN(element.duration)) setDuration(element.duration);
        }
    };

    const handleChange = (e) => {
        //e.stopPropagation();
        editing = true;
    };

    const handleDelete = (e) => {
        //e.stopPropagation();
        deleting = true;
    };

    const handleEdit = (e) => {
        editing = true;
    };

    const handleDocumentDelete = (e) => {
        e.preventDefault();
        setLoading(true)
        setDeleteDocumentModalIsOpen(false);
        deleteUserTalentDocument(userDocument).then((data) => {
            setUserDocument(null);
            setLoading(false)

        });
    };


    const handleClick = (file_path, url) => {
        // when this function called : we may uploading a document or view the document or delete the document

        if (editing || !userDocument) {

            //   setUploadModalError(false);
            //   setUploadModalOpen(true);
            editing = false;
            return;
        } else if (deleting) {
            deleting = false;
            if (userDocument) {
                if (userDocument.courses !== "[]") {
                    //   setUploadError(
                    //     "This document cannot be deleted beacause it is in a contest "
                    //   );
                    //   openModal();
                } else {
                    setDeleteDocumentModalIsOpen(true);
                    // if (window.confirm("Are you sure you want to delete this item ?")) {

                    // }
                }
            }
        }
        // else openModal(file_path, url);
    };

    return (
        <div>
            <ModalDeleteUserDocument
                handleDocumentDelete={handleDocumentDelete}
                isOpen={deleteDocumentModalIsOpen}
                setIsOpen={setDeleteDocumentModalIsOpen}
            />
            <div className="portfolio-item flex-center ">
                <h3>{parse(translate(translations,"Uploaded documents"))}</h3>
                <div className="relative w-60p portfolio-item-preview ">
                    
                    {duration && (
                        <p className="duration">{toTime(Math.round(duration))}</p>
                    )}
                    <span className="thumb-info ">
                        <span
                            onClick={() => {
                                handleClick(
                                    userDocument && userDocument.file,
                                    userDocument && userDocument.url
                                );
                            }}
                            title={
                                userDocument && userDocument.courses != "[]"
                                    ? getCourseNames(userDocument && userDocument.courses)
                                    : "View"
                            }
                            className={`thumb-info-wrapper border-radius-0 upload-icon-container-big`}
                        >
                            {userDocument && isImage(userDocument.file) && (
                                <img
                                    src={
                                        userDocument
                                            ? userDocument.file
                                            : "/assets/images/artist.jpg"
                                    }
                                    className=""
                                    alt=""
                                />
                            )}
                            {userDocument && isDocument(userDocument.file) && (
                                <img
                                    src="/assets/images/pdf_icon.png"
                                    style={{ width: 100 }}
                                    className=""
                                    alt=""
                                />
                            )}

                            {userDocument && isYoutube(userDocument.url) && (
                                <img
                                    src={`https://img.youtube.com/vi/${getYoutubeId(
                                        userDocument.url
                                    )}/0.jpg`}
                                    className="img-fluid"
                                    alt=""
                                />
                            )}

                            {userDocument &&
                                userDocument.url != null &&
                                userDocument.url.includes("firebasestorage") && (
                                    <>
                                        <video
                                            preload="metadata"
                                            onLoadedMetadata={handleVideoMounted}
                                            ref={handleVideoMounted}
                                        >
                                            <source src={userDocument.url} type="video/mp4" />
                                        </video>
                                    </>
                                )}

                            {!userDocument && !loading && (
                                <img
                                    src="assets/images/file_upload.png"
                                    className="upload-icon border-radius-0  height-122"
                                    alt=""
                                />
                            )}

                            {userDocument && (
                                <span className="infoicon">
                                    {isYoutube(userDocument.url) ? (
                                        <i className="fab fa-youtube"></i>
                                    ) : "mp4".split(",").includes("mp4") ? (
                                        <i className="fa fa-play"></i>
                                    ) : (
                                        <i className="fa fa-search-plus"></i>
                                    )}
                                </span>
                            )}
                            <span className="thumb-info-action ">
                                {!userDocument && (
                                    <span
                                        onClick={handleChange}
                                        className={`thumb-info-action-icon bg-dark opacity-8 ${userDocument ? "" : "no-doc"
                                            }`}
                                        title="Upload new file"
                                    >
                                        <i className="fa fa-plus"></i>
                                    </span>
                                )}

                                {(userDocument && userDocument.courses === "[]") && (
                                    <span
                                        onClick={() => {
                                            handleDelete();
                                        }}
                                        className="thumb-info-action-icon bg-dark opacity-8 "
                                    >
                                        <i className="fa fa-trash"></i>
                                    </span>
                                )}

                                {(userDocument && userDocument.courses === "[]") &&
                                    <span
                                        onClick={() => {
                                            handleEdit();
                                        }}
                                        className="thumb-info-action-icon bg-dark opacity-8 r-30 "
                                    >
                                        <i className="fa fa-edit"></i>
                                    </span>
                                }

                            </span>

                            {progress > 0 && progress < 100 && (
                                <>
                                    <div className="progress-container">
                                        <p>{progress} %</p>
                                        <progress id="file" value={progress} max="100">
                                            {progress} %
                                        </progress>
                                    </div>
                                </>
                            )}

                            <LoadingOverlay key={loading + "ld"} loading={loading} />

                        </span>
                    </span>
                </div>
            </div>
            {showVideoMessage &&
                <p className="mt-2 kfont lan-text-right font-11" style={{ lineHeight: 1.8 }}>
                    <i className="fa fa-check color-green"></i> &nbsp;
                    {parse(translate("your document is uploaded"))}
                    <br />
                    {/* <a href={userDocument && userDocument.url} target="_blank">
          <i className="fa fa-download"></i> {translate("click to download")}
        </a> */}
                </p>}
        </div>
    )
}

export default UserDocumentPreview


